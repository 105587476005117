import { IonButton, IonCol, IonGrid, IonInput, IonItem, IonLabel, IonRow, IonSpinner, IonTextarea } from "@ionic/react"
import { useEffect, useState } from "react"
import { UserImageSelector } from "../components/UserImageSelector"
import { useData } from "../services/DataProvider"
import InfoStub from "./InfoStub"
import { useAuth } from "../services/AuthProvider"

export const MyInfo:React.FC = ()=>{
  const {marketingProfile, loadingMarketingProfile, uploadMarketingProfile, permissions, loadingPermissions} = useData();
  const [editedProfile, setEditedProfile] = useState({} as any);
  const [edited, setEdited] = useState(false);
  const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
  const { currentUser } = useAuth();

  // Reinitialize the edited profile whenever the fetched marketing profile changes.
  useEffect(()=>{
    setEditedProfile(marketingProfile);
  }, [marketingProfile]);

  const updateProperty = (propertyName:string, value:string)=>{
    if (editedProfile[propertyName] !== value) {
      setEdited(true);
      setEditedProfile({...editedProfile, [propertyName]:value});
    }
  };

  const attemptUpload = ()=>{
    // TODO: Input validation
    uploadMarketingProfile(editedProfile);
    setEdited(false);
  }

  const makeInput = (propertyName: string, placeholder: string)=>{
    return <IonInput placeholder={placeholder} onInput={(event)=>{updateProperty(propertyName, "" + event.currentTarget.value)}} value={editedProfile[propertyName]} />
  }
  
  const portraitStyle: React.CSSProperties = {
    maxHeight: "10em",
    aspectRatio: 9/16,
    objectFit: "cover",
    objectPosition: "center",
  }

  const logoStyle: React.CSSProperties = {
    maxHeight: "10em",
    aspectRatio: 16/5,
    objectFit: "contain",
    objectPosition: "center",
  }

  return <InfoStub title="My Info">
    {/* <p>Settings will go here, including name, advisor or team picture, advisor credentials, firm name, firm logo, pantone logo color #'s, typed-in disclosures</p> */}
    <IonGrid>
      <IonRow>
        <IonCol><IonButton disabled={!edited} onClick={attemptUpload}>{loadingMarketingProfile?<IonSpinner/>:<></>}Save</IonButton>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol><p>(Account Email: {currentUser?.email})</p>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol><IonItem style={{display:"inline-block"}}><IonLabel position="stacked">Name</IonLabel> {makeInput("name", "First Last")} </IonItem>, <IonItem style={{display:"inline-block"}}><IonLabel position="stacked">Credentials</IonLabel>{makeInput("credentials", "CFA, PhD, MD, JD, etc.")}</IonItem></IonCol>
      </IonRow>
      <IonRow>
        <IonCol><IonItem style={{display:"inline-block"}}><IonLabel position="fixed" class="ion-text-wrap">Title / Position</IonLabel>{makeInput("role", "Senior Financial Advisor")}</IonItem></IonCol>
      </IonRow>
      <IonRow>
        <IonCol><h3>Portrait:</h3><UserImageSelector fileName="portrait" fallbackImageURL="/assets/user_fallbacks/portrait.jpg" style={portraitStyle}/></IonCol>
        <IonCol><h3>Organization Logo:</h3><UserImageSelector fileName="logo" fallbackImageURL="/assets/user_fallbacks/logo.jpg" style={logoStyle}/></IonCol>
      </IonRow>
      <IonRow>
        <IonCol><IonItem style={{display:"inline-block"}}><IonLabel position="fixed" class="ion-text-wrap">Organization Name</IonLabel>{makeInput("organization", "Prosperity Advising, LLC")}</IonItem></IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <p>Pantone Colors (Hex Codes)</p>
          <IonItem style={{display:"inline-block"}}><IonLabel position="stacked">Principal Logo Color</IonLabel>{makeInput("color1", "#2596be")}</IonItem>, <IonItem style={{display:"inline-block"}}><IonLabel position="stacked">Secondary Logo Color</IonLabel>{makeInput("color2", "#e28743")}</IonItem>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol><IonLabel>Disclosures Text</IonLabel><IonTextarea style={{border:"1px solid black", maxWidth:"50em", margin:"auto", textAlign:"left"}} value={editedProfile["disclosures"]} onInput={(event)=>{updateProperty("disclosures", "" + event.currentTarget.value)}}/></IonCol>
      </IonRow>
      <IonRow><IonCol>
        {/* {permissions.hasSubscription || permissions.hasTestSubscription?
          <IonButton disabled={loadingPermissions} href={isLocalhost? "https://billing.stripe.com/p/login/test_aEU4ju2TB9Mx7baaEE" : "https://billing.stripe.com/p/login/8wMdRt7gZ0PQ0WkfYY"} target="_blank">{loadingPermissions? <IonSpinner/>:<></>}Manage Billing</IonButton>
        :
          <IonButton disabled={loadingPermissions} routerLink="/billing">{loadingPermissions? <IonSpinner/>:<></>}Set Up Billing</IonButton>
        } */}
        
      </IonCol></IonRow>
    </IonGrid>
  </InfoStub>
}