import { IonInput, IonItem, IonLabel, IonRange, IonSelect, IonSelectOption, IonText } from "@ionic/react";
import { QuestionType, SurveyAnswers } from "./question-types";

interface QuestionProps {
  questionKey: keyof SurveyAnswers;
  question: QuestionType;
  value: any;
  onChange: (key: keyof SurveyAnswers, value: any) => void;
  editMode?: boolean;
}

export const Question: React.FC<QuestionProps> = ({ questionKey, question, value, onChange, editMode }) => {
  if (!editMode) {
    return (
      <IonItem>
        <IonLabel position="stacked">{question.question}</IonLabel>
        <IonText>{(typeof value === "string"? value : JSON.stringify(value)) || "---"}</IonText>
      </IonItem>
    );
  }

  switch (question.type) {
    case 'text':
      return <TextInput question={question.question} value={value} onChange={(v) => onChange(questionKey, v)} />;
    case 'number':
      return <NumberInput question={question.question} value={value} onChange={(v) => onChange(questionKey, v)} />;
    case 'multipleChoice':
      return <MultipleChoice question={question.question} options={question.options} value={value} multiple={question.multiple} onChange={(v) => onChange(questionKey, v)} />;
    case 'slider':
      return <SliderInput question={question.question} value={value} min={question.min} max={question.max} onChange={(v) => onChange(questionKey, v)} />;
    default:
      return null;
  }
};

// Interfaces for different input components
interface TextInputProps {
  question: string;
  value: string;
  onChange: (value: string) => void;
}

const TextInput: React.FC<TextInputProps> = ({ question, value, onChange }) => (
  <IonItem>
    <IonLabel position="stacked">{question}</IonLabel>
    <IonInput value={value} onIonChange={(e) => onChange(e.detail.value!)} />
  </IonItem>
);

interface NumberInputProps {
  question: string;
  value: number;
  onChange: (value: number) => void;
}

const NumberInput: React.FC<NumberInputProps> = ({ question, value, onChange }) => (
  <IonItem>
    <IonLabel position="stacked">{question}</IonLabel>
    <IonInput type="number" value={value} onIonChange={(e) => onChange(parseFloat(e.detail.value!))} />
  </IonItem>
);

interface MultipleChoiceProps {
  question: string;
  options: string[];
  value: string[];
  multiple?: boolean;
  onChange: (value: string[]) => void;
}

const MultipleChoice: React.FC<MultipleChoiceProps> = ({ question, options, value, multiple, onChange }) => (
  <IonItem>
    <IonLabel position="stacked">{question}</IonLabel>
    <IonSelect multiple={multiple} value={value} onIonChange={(e) => onChange(e.detail.value)}>
      {options.map((option) => (
        <IonSelectOption key={option} value={option}>
          {option}
        </IonSelectOption>
      ))}
    </IonSelect>
  </IonItem>
);

interface SliderInputProps {
  question: string;
  value: number;
  min: number;
  max: number;
  onChange: (value: number) => void;
}

const SliderInput: React.FC<SliderInputProps> = ({ question, value, min, max, onChange }) => (
  <IonItem>
    <IonLabel position="stacked">{question}: {value}</IonLabel>
    <IonRange min={min} max={max} value={value} onIonChange={(e) => onChange(e.detail.value as number)}/>
  </IonItem>
);