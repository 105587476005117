import { IonAccordion, IonAccordionGroup, IonButton, IonIcon, IonInput, IonItem, IonLabel, IonText, IonTextarea, IonToast } from "@ionic/react"
import { Link, RouteComponentProps } from "react-router-dom"

import { useCallback, useEffect, useState } from "react";
import { useAuth } from "../services/AuthProvider";
import { FullMetadata, getDownloadURL, getMetadata, listAll, ref, updateMetadata } from "firebase/storage";
import { functions, summarizeTranscript, transcribeAudio } from "../App";
import { storage } from "../App";
import { httpsCallable } from "firebase/functions";
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonSpinner } from "@ionic/react";
import { LoginGuard } from "../pages/LoginGuard";
import InfoStub from "../pages/InfoStub";
import { copy, filter } from "ionicons/icons"
import { useRecordings } from "../services/RecordingsProvider";

const truncateAfterWords = (input: string, limit:number = 20) : string => {
  const words = input.split(" ");
  return words.length > limit ? `${words.slice(0, limit).join(" ")}...` : input;
};

export const RecordingInspector: React.FC<RouteComponentProps<{date:string}>> = ({ match }) => {
  const date = match.params.date;

  const { currentUser } = useAuth();
  let uid = currentUser?.uid || "NO_USER_IDENTIFIED";
  // if (uid === "gQ6uSdk0sNSWwJQL1oPEJFK6Q5B2" || uid==="OHgrHEoqvQQToBQl49BTeyDqsNg1") { // For admin testing purposes.
  //   uid = "aWdbYqMOYSSyAWBbtjUUXf3dsJt2";
  // }

  const { filesList } = useRecordings();

  const [loadingListings, setLoadingListings] = useState(false);


  const [file, setFile] = useState<FullMetadata | null>(null);

  const [downloadURL, setDownloadURL] = useState<string | null>(null);

  const [computingTranscription, setComputingTranscription] = useState(false);
  const [transcript, setTranscript] = useState<{"speaker":number, "text":string}[]>([]);

  const numSpeakers = Math.max(...transcript.map(item => item.speaker), -1) + 1;
  const [listOfSpeakers, setListOfSpeakers] = useState<string[]>([]);
  const sampleUtterances = listOfSpeakers.map((speaker, index)=>{
      const filteredUtterances = transcript.filter((element)=>{return element.speaker===index});
      const longestUtterance = filteredUtterances.reduce((acc, curr) => {
        return curr.text.length > acc.length ? curr.text : acc;
      }, "");
      return truncateAfterWords(longestUtterance);
  });
  const [savingSpeakers, setSavingSpeakers] = useState(false);

  const [computingSummary, setComputingSummary] = useState(false);
  const [summary, setSummary] = useState("");
  const [summaryEdited, setSummaryEdited] = useState(false);

  const [showError, setShowError] = useState(false);

  const [showToast, setShowToast] = useState(false); // For copying to the clipboard.

  const fetchMetadata = useCallback(async () => {
    // setLoadingListings(true);
    // const storageRef = ref(storage, `user/${uid}/audio/${date}.wav`);
    
    // getMetadata(storageRef).then(function(result) {
    //   setFile(result);
    //   if(result.customMetadata?.summary) {
    //     setSummary(result.customMetadata.summary);
    //   }
    //   if(result.customMetadata?.speakers) {
    //     setListOfSpeakers(JSON.parse(result.customMetadata.speakers));
    //   }
    //   if(result.customMetadata?.transcript) {
    //     setTranscript(JSON.parse(result.customMetadata.transcript));
    //   }
    // }).catch(function(error) {
    //   // Handle any errors
    // }).finally(()=>{
    //   setLoadingListings(false);
    // });
    
    const result = filesList.filter((file)=>{return file.fullPath.includes(date)})[0];
    if (result) {
      setFile(result);
      if(result.customMetadata?.summary) {
        setSummary(result.customMetadata.summary);
      }
      if(result.customMetadata?.speakers) {
        setListOfSpeakers(JSON.parse(result.customMetadata.speakers));
      }
      if(result.customMetadata?.transcript) {
        setTranscript(JSON.parse(result.customMetadata.transcript));
      }

      // https://firebase.google.com/docs/storage/web/download-files
      const fileRef = ref(storage, result?.fullPath);
      setDownloadURL(await getDownloadURL(fileRef));
      // setDownloadURL(file?.fullPath || "");
    }
  }, [filesList]);
  
  useEffect(() => {
    fetchMetadata();
  }, [fetchMetadata]);

  const computeUtterances = async () => {
    if(!file) {
      return;
    }
    setComputingTranscription(true);
    // Call Firebase Function to transcribe audio
    transcribeAudio({ path: file?.fullPath }).then((result)=>{
      const data = result.data as any;
      setTranscript(data.transcript);
      setShowError(false);
    }).catch((reason)=>{
      console.log(reason);
      // setTranscript("");
      // setSummary("Processing Failed. Feel free to refresh the page to try again, or contact support.");
      setShowError(true);
    }).finally(()=>{
      setComputingTranscription(false);
    })
  }

  const saveSpeakers = async () => {
    if (file?.ref) {
      setSavingSpeakers(true);
      const newCustomMetadata = {
        ...file?.customMetadata,
        speakers: JSON.stringify(listOfSpeakers),
      }
  
      await updateMetadata(file.ref, {customMetadata: newCustomMetadata});
      setSavingSpeakers(false);

      await computeSummary();
    }
  }

  const computeSummary = async () => {
    if(!file) {
      return;
    }
    setComputingSummary(true);
    // Call Firebase Function to transcribe audio
    summarizeTranscript({ path: file?.fullPath }).then((result)=>{
      const data = result.data as any;
      setSummary(data.summary);
      setShowError(false);
    }).catch((reason)=>{
      console.log(reason);
      setShowError(true);
    }).finally(()=>{
      setComputingSummary(false);
    })
    
  }

  const saveSummary = async () => {
    if (file?.ref) {
      setSavingSpeakers(true); // TODO: Change to more specific processing flag.
      const newCustomMetadata = {
        ...file?.customMetadata,
        summary: summary,
      }
  
      await updateMetadata(file.ref, {customMetadata: newCustomMetadata});
      setSavingSpeakers(false);
      setSummaryEdited(false);
    }
  }

  // Not sure this is preventing re-call to compute utterances when transcript is already present.
  // TODO
  useEffect(()=>{
    if (file && !transcript.length) {
      computeUtterances();
    }
  }, [file, transcript]);

  // Necessary to reinitialize the list of speakers if the transcript (and consequently numSpeakers) changes.
  useEffect(()=>{
    if (file?.customMetadata?.speakers) {
      setListOfSpeakers(JSON.parse(file?.customMetadata?.speakers));
    } else {
      setListOfSpeakers(()=>Array.from({ length: numSpeakers }, () => ""));
    }
  }, [file, numSpeakers]);

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(summary);
      setShowToast(true);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  const label = file?.customMetadata?.label;

  const dateString = date? (new Date(Number.parseInt(date))).toLocaleString() : "No Timestamp";

  return <LoginGuard>
    <InfoStub title="Notes">
      {loadingListings? <IonSpinner/>: <></>}
      <IonCard>
        <IonCardHeader><IonCardTitle>{dateString}</IonCardTitle></IonCardHeader>
        <IonCardContent>
          <p>Meeting with: {label}</p>
          <hr/>
          {showError && !computingTranscription && !computingTranscription? <p><IonText color="danger">Processing Failed. Feel free to refresh the page to try again, or contact support.</IonText></p>: <></>}
          {computingTranscription || computingSummary? <><IonSpinner/><p>Processing your audio. This may take up to 5 minutes.</p><br/></>:<></>}

          {downloadURL && <><IonButton color="tertiary" href={downloadURL} download={file?.name} target="_blank">Download Recording</IonButton><br/><br/></>}
          
          {summary? <>
            <h2>Summary:</h2>
            <IonButton color="success" onClick={copyToClipboard}><IonIcon icon={copy}/> Copy to Clipboard</IonButton>
            <IonTextarea autoGrow value={summary} onIonInput={(event)=>{setSummary(event.target.value + ""); setSummaryEdited(true);}} style={{textAlign:"left"}}/>
            {/* {summary.split("\n").map((paragraph, index)=>{return <p key={index} style={{textAlign:"left"}}>{paragraph}</p>;})} */}
            <IonButton disabled={!summaryEdited} onClick={saveSummary}>Save Edits</IonButton>
          </> : (computingSummary || computingTranscription? <></>: <>
            {listOfSpeakers.length? <><h2>Identify meeting participants?</h2><br/></>:<></>}

            {listOfSpeakers.map((speaker, index)=>{
              return <div key={index}>
                <p>... {sampleUtterances[index]} ...</p>
                <IonItem>
                  <IonLabel position="stacked">Who was speaking?</IonLabel>
                  <IonInput value={listOfSpeakers[index]} onInput={(event)=>{
                      const newList = [...listOfSpeakers];
                      newList[index] = event.currentTarget.value + "";
                      setListOfSpeakers(newList);
                  }}></IonInput>
                </IonItem>
                <br/>
              </div>
            })}

            {listOfSpeakers && transcript.length? 
              <IonButton onClick={saveSpeakers}>Save + Generate Summary</IonButton>
            : <></>}
          </>)}
          <hr/>

          {transcript.length? 
            <IonAccordionGroup value={numSpeakers>0 && !summary? "transcript":""}>
              <IonAccordion value="transcript">
                <IonItem slot="header">
                  <IonLabel>Full Transcript</IonLabel>
                </IonItem>
                {/* {transcript.split("\n").map((paragraph, index)=>{return <p key={index} style={{textAlign:"left"}}>{paragraph}</p>;})} */}
                <div slot="content">
                  {transcript.map((element:{"speaker":number, "text":string}, id)=>{
                    return <p key={id} style={{textAlign:"left"}}><b>{listOfSpeakers[element.speaker] || "Speaker " + element.speaker}:</b> {element.text}</p>
                  })}
                </div>
              </IonAccordion>
            </IonAccordionGroup>
          : <></>}
        </IonCardContent>
      </IonCard>
      <IonButton color="secondary" routerLink="/smart-notes">See all Recordings</IonButton>
      <IonButton color="secondary" routerLink="/smart-notes/record">Record a New Meeting</IonButton>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message="Text copied to clipboard"
        duration={2000}
      />
    </InfoStub>
  </LoginGuard>
}