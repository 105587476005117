import React, { useState, useEffect, useMemo } from 'react';
import { useData } from '../services/DataProvider';
import { Client } from '../services/data-types';

interface ClientMultiSelectorProps {
  onSelectionChange?: (selectedClients: string[]) => void; // Callback to inform parent components of the selection change
}

const ClientMultiSelector: React.FC<ClientMultiSelectorProps> = ({ onSelectionChange }) => {
  const { clientList, updateClientList } = useData();
  const clientNames = useMemo(() => {
    return clientList.map(clientRecord => clientRecord.fullName);
  }, [clientList]);

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [filteredOptions, setFilteredOptions] = useState<string[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  const isValidToAdd = useMemo(() => {
    return searchTerm.trim().length > 0 && !clientNames.map(name => name.toLowerCase()).includes(searchTerm.trim().toLowerCase());
  }, [searchTerm, clientNames]);

  useEffect(() => {
    setFilteredOptions(
      clientNames.filter(clientName =>
        clientName.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, clientNames]); // Use clientList directly as a dependency

  useEffect(() => {
    if (onSelectionChange) {
      onSelectionChange(selectedOptions);
    }
  }, [selectedOptions, onSelectionChange]); // Notify parent component whenever selectedOptions changes

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const newSelectedOptions = event.target.checked
      ? [...selectedOptions, value]
      : selectedOptions.filter(option => option !== value);

    setSelectedOptions(newSelectedOptions);
  };

  const handleCreateNewItem = () => {
    if (!isValidToAdd) {
      return;
    }

    const newClient: Client = {
      apiVersion: "1",
      fullName: searchTerm.trim(),
    };

    updateClientList([...clientList, newClient]);
    setSelectedOptions([...selectedOptions, searchTerm.trim()]);
    setSearchTerm("");
  };

  return (
    <div>
      <input
        type="text"
        placeholder="Search or add new..."
        value={searchTerm}
        onChange={handleSearchChange}
        style={{ marginTop: '.5rem' }}
      />
      <div style={{ height: '10rem', overflowY: 'auto' }}>
        {isValidToAdd && (
          <div style={{ marginTop: '10px' }}>
            <button onClick={handleCreateNewItem}>Add "{searchTerm}"</button>
          </div>
        )}
        {filteredOptions.map((option) => (
          <div key={option} style={{ display: 'block', marginTop: '.25rem' }}>
            <label>
              <input
                type="checkbox"
                value={option}
                checked={selectedOptions.includes(option)}
                onChange={handleOptionChange}
              />
              {option}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ClientMultiSelector;