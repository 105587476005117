import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { collection, onSnapshot, doc, setDoc } from 'firebase/firestore'; // Firestore functions
import { useData } from '../DataProvider';
import { firestore } from '../../App';
import {
  Parameter,
  ParameterType,
} from './parameters-schema';
import { assert } from 'superstruct';

interface ParametersContextProps {
  parameters: ParameterType[] | null;
  updateParameter: (id: string, newParameter: ParameterType) => Promise<void>;
  loadingParameters: boolean;
}

const ParametersContext = createContext<ParametersContextProps | undefined>(undefined);

export const useParameters = (): ParametersContextProps => {
  const context = useContext(ParametersContext);
  if (!context) {
    throw new Error('useParameters must be used within a ParametersProvider');
  }
  return context;
};

interface ParametersProviderProps {
  children: ReactNode;
}

export const ParametersProvider: React.FC<ParametersProviderProps> = ({ children }) => {
  const [parameters, setParameters] = useState<ParameterType[] | null>(null);
  const [loadingParameters, setLoadingParameters] = useState(true);
  const { permissions } = useData(); // Assuming useData provides user permissions

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(firestore, 'parameters'), (snapshot) => {
      let params: ParameterType[] = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }) as ParameterType);

      // Check if 'internationalEquityPercentage' exists
      const internationalEquityExists = params.some(param => param.id === 'internationalEquityPercentage');
      
      // If it doesn't exist, add a default empty version
      if (!internationalEquityExists) {
        params = [
          ...params,
          {
            id: 'internationalEquityPercentage',
            displayTitle: 'International Equity Percentage',
            parameterDescription: 'This parameter is used to define buckets based on the amount of exposure the client has to international equities, as a percentage of the value of their equity investments.',
            type: 'percentageCutoffList',
            data: [],
          } as ParameterType,
        ];
      }

      setParameters(params);
      setLoadingParameters(false);
    }, (err) => {
      console.error(err);
      setLoadingParameters(false);
    });

    return () => unsubscribe();
  }, [firestore]);

  const updateParameter = async (id: string, newParameter: ParameterType) => {
    // TODO: This seemed to be blocking it when it shouldn't. Not sure why. Turning off for now.
    // if (!permissions.isAdmin) {
    //   throw new Error('Unauthorized: Only admins can update parameters');
    // }

    try {
      assert(newParameter, Parameter); // Validate the new parameter
      const parameterDoc = doc(firestore, 'parameters', id);
      await setDoc(parameterDoc, newParameter);
    } catch (err) {
      throw new Error((err as Error).message);
    }
  };

  return (
    <ParametersContext.Provider value={{ parameters, updateParameter, loadingParameters }}>
      {children}
    </ParametersContext.Provider>
  );
};
