import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonContent, IonGrid, IonHeader, IonPage, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import { Link } from 'react-router-dom';
import { AdminImageSelector } from '../components/AdminImageSelector';
import { AdminToggle } from '../components/AdminToggle';
import { Breadcrumbs } from '../components/Breadcrumbs';
import { Logout } from '../components/Logout';
import './Home.css';
import { AdminLinks } from '../components/admin-links';

const Home: React.FC = () => {
  const tileStyle: React.CSSProperties = {
    maxWidth: "75%",
    maxHeight: "75%",
    width: "75%",
    aspectRatio: 1, // Expressed as a fraction
    display: "block",
    margin: "auto",
    objectFit: "cover",
    objectPosition: "center",
  };

  // captionStyle makes images centered the same way, regardless of differences in text length. The downside is that the caption can bleed off / be covered by the bottom. For that reason, the padding is added to the columns (below). Unfortunately, this assumes a maximum length of caption.
  const captionStyle: React.CSSProperties = {
    position: "absolute", 
    left:"50%", transform: 
    "translate(-50%, 0)",
  }

  const columnStyle: React.CSSProperties = {
    padding: "2em",
  }

  return (
    // <IonPage>
    //   <IonHeader>
    //     <IonToolbar>
    //       <IonTitle>Blank</IonTitle>
    //     </IonToolbar>
    //   </IonHeader>
    //   <IonContent fullscreen>
    //     <IonHeader collapse="condense">
    //       <IonToolbar>
    //         <IonTitle size="large">Blank</IonTitle>
    //       </IonToolbar>
    //     </IonHeader>
    //     <p>Demo Text Here...</p>
    //     <p>Demo Text Here...</p>
    //     <p>Demo Text Here...</p>
    //     <p>Demo Text Here...</p>
    //   </IonContent>
    // </IonPage>
    <IonPage>
      <IonContent>
        <IonGrid style={{position:"absolute", top:"0", height:"100%", width:"100%", display: "flex", flexDirection: "column"}}>
          <IonRow>
            <IonCol>
              <p style={{textAlign:"left"}}><Breadcrumbs/></p>
            </IonCol>
            <IonCol>
              <h1 style={{textAlign:"center"}}>My Junior Advisor</h1>
              <h2 style={{textAlign:"center"}}>Content Studio</h2>
            </IonCol>
            <IonCol>
              <p style={{textAlign:"right"}}><AdminLinks/><Logout/></p>
            </IonCol>
          </IonRow>
          <IonRow style={{height:"100%", display: "flex", alignItems: "center", textAlign:"center"}}>
            <IonCol size="12" sizeSm="4" style={columnStyle}>
              <Link to="/marketing-copy">
                <AdminImageSelector style={tileStyle} fallbackImageURL="/assets/fallback_tiles/marketing_copy.jpg" fileName="marketing_copy"/>
                {/* <img style={tileStyle} src="/assets/fallback_tiles/marketing_copy.jpg"/> */}
                <p style={captionStyle}>Marketing Copy</p>
              </Link>
            </IonCol>
            <IonCol size="12" sizeSm="4" style={columnStyle}>
              <Link to="/smart-notes">
                <AdminImageSelector style={tileStyle} fallbackImageURL="/assets/fallback_tiles/notes.jpg" fileName="virtual_assistant"/>
                {/* <img style={tileStyle} src="/assets/fallback_tiles/virtual_assistant.jpg"/> */}
                <p style={captionStyle}>Smart Notes</p>
              </Link>
            </IonCol>
            <IonCol size="12" sizeSm="4" style={columnStyle}>
              <Link to="/clients">
                <AdminImageSelector style={tileStyle} fallbackImageURL="/assets/fallback_tiles/ai.jpg" fileName="advising_AI"/>
                {/* <img style={tileStyle} src="/assets/fallback_tiles/ai.jpg"/> */}
                <p style={captionStyle}>AI for Advising</p>
              </Link>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Home;
