import { collection, query, where, onSnapshot } from 'firebase/firestore';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { useState, useEffect } from 'react';
import { firestore as db, storage } from "../../App"; // Your firebase configuration
import { ReportRecord, useClients } from './clients-provider';

export interface FileMetadata {
  fileName: string;
  filePath: string;
  clientId: string;
  uploaderId: string;
  uploaderEmail: string;
  documentType: string;
  reportId: string;
  uploadTimestamp: any;
  id: string; // Firestore document ID
  fileUrl?: string; // Optional signed URL
}

const useFileMetadata = (report: ReportRecord) => {
  const [fileMetadataList, setFileMetadataList] = useState<FileMetadata[]>([]);

  useEffect(() => {
    // https://firebase.google.com/docs/firestore/security/rules-query
    // Firebase does some logical inference about query constraints satisfying security rules as written. // This is why we just use a filtering step after querying by client; individual clients won't have that many files associated with them.
    const fileMetadataQuery = query(
      collection(db, 'fileMetadata'),
      // where('reportId', '==', reportId)
      where('clientId', '==', report.clientId)
    );

    const unsubscribe = onSnapshot(fileMetadataQuery, async (snapshot) => {
      const fileMetadataPromises = snapshot.docs.filter(doc => (doc.data() as FileMetadata).reportId === report.id).map(async (doc) => {
        const fileData = doc.data() as FileMetadata;
        const filePath = fileData.filePath;
        const fileRef = ref(storage, filePath);
        
        try {
          const fileUrl = await getDownloadURL(fileRef);
          return { ...fileData, id: doc.id, fileUrl };
        } catch (error) {
          console.error("Error getting download URL:", error);
          return { ...fileData, id: doc.id };
        }
      });

      const fileMetadataArray = await Promise.all(fileMetadataPromises);
      setFileMetadataList(fileMetadataArray);
    });

    return () => unsubscribe();
  }, [report]);

  return fileMetadataList;
};

export default useFileMetadata;