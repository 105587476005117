import React, { useState } from 'react';
import { QuestionType, SurveyAnswers, SurveyKeys, surveyQuestions } from './question-types';
import { Question } from './question';
import { IonButton, IonIcon } from '@ionic/react';
import { createOutline, saveOutline } from 'ionicons/icons';
import { ClientRecord, useClients } from '../../services/client-records/clients-provider';

const SurveyForm: React.FC<{client: ClientRecord}> = ({client}) => {
  const [answers, setAnswers] = useState<Partial<SurveyAnswers>>(client as Partial<SurveyAnswers>);
  const [editMode, setEditMode] = useState(false);
  const { updateClient } = useClients();

  const handleInputChange = (key: keyof SurveyAnswers, value: any) => {
    setAnswers({ ...answers, [key]: value });
  };

  const toggleState = ()=>{
    if (editMode) {
      updateClient(client.id, answers);
    }
    setEditMode(!editMode);
  }

  return (
    <div>
      <IonButton onClick={toggleState}>
        <IonIcon slot="start" icon={editMode ? saveOutline : createOutline} />
        {editMode? 'Save' : 'Edit'}
      </IonButton>
      {Object.keys(surveyQuestions).map((key) => {
        const questionKey = key as SurveyKeys;
        const question = surveyQuestions[questionKey] as QuestionType;
        return (
          <Question
            key={key}
            questionKey={questionKey}
            question={question}
            value={answers[questionKey]}
            onChange={handleInputChange}
            editMode={editMode}
          />
        );
      })}
    </div>
  );
};

export default SurveyForm;
