import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonContent, IonGrid, IonInput, IonItem, IonLabel, IonPage, IonRow, IonSpinner, IonText, IonToggle } from "@ionic/react"
import { FullMetadata, getMetadata, listAll, ref, StorageReference, uploadBytes, UploadMetadata, deleteObject, updateMetadata } from "firebase/storage";
import { useEffect, useMemo, useState } from "react";
import { storage } from "../App";
import { AdminToggle } from "../components/AdminToggle";
import { Breadcrumbs } from "../components/Breadcrumbs"
import { Logout } from "../components/Logout"
import PDFCopyViewer, { PDFViewerProps } from "../components/PDFCopyViewer";
import { useData } from "../services/DataProvider";
import { useAuth } from "../services/AuthProvider";
import { RouteComponentProps } from "react-router";
import { LoginGuard } from "./LoginGuard";

export const MarketingCopy: React.FC<RouteComponentProps<{tags?:string}>> = ({ match })=>{
  const initActiveTags = (match.params.tags || "").split(",").map(tag=>tag.trim().toLowerCase());
  console.log(`initTags: ${initActiveTags}`);
  const { currentUser } = useAuth();
  const { marketingProfile } = useData();
  const [filesList, setFilesList] = useState([] as FullMetadata[]);
  const [selectedDocumentIndex, setSelectedDocumentIndex] = useState(null as null | number);

  const [loadingListings, setLoadingListings] = useState(true);

  // Admin Data for uploading a new file:
  const [title, setTitle] = useState<string>("");
  const [tags, setTags] = useState<string>("");
  const [hiddenTags, setHiddenTags] = useState<string>("")
  const [file, setFile] = useState<File | null>(null);
  const [recolor, setRecolor] = useState(false);
  const [unaffiliated, setUnaffiliated] = useState(false);

  const resetFields = ()=>{
    setTitle("");
    setTags("");
    setHiddenTags("");
    setFile(null);
    setRecolor(false);
    setUnaffiliated(false);
  }

  // Admin - which edit field is selected.
  const [editView, setEditView] = useState("");
  const updateEditView = (newEditView:string)=>{
    if(newEditView !== editView) {
      setEditView(newEditView);
      const selectedFile = filesList.filter((file)=>{return file.fullPath === newEditView})?.[0]
      if (selectedFile) {
        setTitle(selectedFile.customMetadata?.title || selectedFile.name);
        setTags(selectedFile.customMetadata?.tags || "");
        setHiddenTags(selectedFile.customMetadata?.hiddenTags || "");
        setRecolor(selectedFile.customMetadata?.recolor === "true");
        setUnaffiliated(selectedFile.customMetadata?.unaffiliated === "true");

        setFile(null); // If they move away from uploading a file, clear it out.
      } else {
        resetFields();
      }
    }
  }

  // TODO: Add save functionality. (And reset edit view.)
  const saveMetadata = () => {
    const selectedFileMetadata = filesList.filter((file)=>{return file.fullPath === editView})?.[0]
      if (selectedFileMetadata?.ref) {
        const newCustomMetadata = {
          title: title,
          tags: tags,
          hiddenTags: hiddenTags,
          recolor: "" + recolor,
          unaffiliated: "" + unaffiliated,
        }

        updateMetadata(selectedFileMetadata.ref, {customMetadata: newCustomMetadata}).then(()=>{
          resetFields();
          setEditView("");
          fetchMetadata();
        });
      }
  }

  // TODO: Add to todo backlog - overflow-scroll text input fields. (e.g. admin view on small window.) TODO: Mobile-friendly marketing copy.

  // Identify largest index on existing files:
  const largestIndex = Math.max(-1, ...filesList.map((metadata)=>{return metadata.name.split("-")[0]}).map(Number) ); // Note that this uses the "-" as a separator character, matching with file creation below.

  // Search + Filter Functionality.
  const [searchString, setSearchString] = useState<string>("");
  const allTags: string[] = filesList
    .map((file)=>{return file.customMetadata?.tags || ""})
    .join(',') // Merge all of the strings into one big string.
    .split(',') // Split this string by commas.
    .map(tag => tag.trim())
    .filter(tag => tag.length > 0); // Trim whitespace from each tag.
  const uniqueTags: string[] = [...new Set(allTags)].sort(); // Remove duplicates.
  // const [activeTags, setActiveTags] = useState<boolean[]>(uniqueTags.map(tag => initActiveTags.includes(tag.toLowerCase()))); // Problem is that this is initialized before the filesList is loaded.
  const [activeTags, setActiveTags] = useState<{[key: string]: boolean}>(Object.fromEntries(initActiveTags.map(tag => [tag, true])));
  console.log(`Active Tags: ${JSON.stringify(activeTags)}`); 
  const filteredFilesList = filesList.filter((fileListing)=>{
    let containsTags = true;
    uniqueTags.forEach((tag, i)=>{
      if(activeTags[tag] && !fileListing.customMetadata?.tags?.split(',').map(tag=>tag.trim().toLowerCase()).includes(tag)) {
        containsTags = false;
      }
    })
    return (fileListing.customMetadata?.title|| fileListing.name)?.includes(searchString) && containsTags;
  });

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setFile(e.target.files[0]);
      setEditView("upload-new-file");
    }
  };

  const handleUpload = () => {
    if (!file) {
      return;
    }

    // Old index was filesList.length. New is largestIndex + 1
    const storageRef = ref(storage, `admin/copy/${largestIndex + 1}-${file.name}`);
    const metadata: UploadMetadata = {
      contentType: "application/pdf",
      customMetadata: {
        title: title,
        tags: tags,
        hiddenTags: hiddenTags,
        recolor: "" + recolor,
        unaffiliated: "" + unaffiliated,
        date: new Date().toISOString(), // TODO: Replace with dating on server-side.
      },
    };

    uploadBytes(storageRef, file, metadata).then((snapshot) => {
      console.log("File uploaded successfully"); // TODO: Remove
      resetFields();

      fetchMetadata();
    });
  };

  const handleDelete = (storageRef?: StorageReference) => {
    if (storageRef) {
      deleteObject(storageRef).then(()=>{
        fetchMetadata();
      });
    }
  };

  const fetchMetadata = async () => {
    setLoadingListings(true);
    const storageRef = ref(storage, "admin/copy");
    // https://firebase.google.com/docs/storage/web/download-files
    // https://stackoverflow.com/questions/37335102/how-to-get-a-list-of-all-files-in-cloud-storage-in-a-firebase-app
    // Now we get the references of these images
    listAll(storageRef).then(function(result) {
      const promises = result.items.map((ref)=>{
        return getMetadata(ref);
      });
      Promise.all(promises).then((metadata)=>{
        setFilesList(metadata);
      });
    }).catch(function(error) {
      // Handle any errors
    }).finally(()=>{
      setLoadingListings(false);
    });
  };
  
  useEffect(() => {
    fetchMetadata();
    
    // // TODO: DEBUGGING
    // currentUser?.getIdTokenResult().then((idTokenResult)=>{
    //   console.log("ID TOKEN:");
    //   console.log(JSON.stringify(idTokenResult));
    // })
  }, []);

  // If one of the files is selected, set the relevant properties for generating the PDF
  let pdfProperties = null as PDFViewerProps | null;

  if (selectedDocumentIndex!==null && filesList[selectedDocumentIndex]) {
    pdfProperties= {
      storageRef: ref(storage, filesList[selectedDocumentIndex].fullPath),
      toRecolor: (filesList[selectedDocumentIndex].customMetadata?.recolor)==="true",
      unaffiliated: (filesList[selectedDocumentIndex].customMetadata?.unaffiliated)==="true",
    }
  }

  // Added useMemo to prevent flickering / redundant re-render on textbox editing.
  // May be some latent state misuse, but this seems to be solving the problem, so that's fine.
  const pdfViewerComponent = useMemo(()=>{
    if (pdfProperties) {
      return <PDFCopyViewer {...pdfProperties} />
    } else {
      return <></>
    }
  }, [selectedDocumentIndex]);

  // A helper element that can be relocated depending on which field is being edited.
  const setPropertiesElements = <div  onClick={(event)=>{event.stopPropagation();}}>
    <IonItem>
      <IonLabel>Title</IonLabel>
      <IonInput type="text" placeholder="Title" onInput={(e) => setTitle("" + e.currentTarget.value)} value={title} />
    </IonItem>
    <IonItem>
      <IonLabel>Comma-Separated Tags</IonLabel>
      <IonInput type="text" placeholder="Tags" onInput={(e) => setTags("" + e.currentTarget.value)} value={tags} />
    </IonItem>
    <IonItem>
      <IonLabel>Hidden Tags (Comma-Separated, used by Recommendation Engine)</IonLabel>
      <IonInput type="text" placeholder="Tags" onInput={(e) => setHiddenTags("" + e.currentTarget.value)} value={hiddenTags} />
    </IonItem>
    <IonItem>
      <IonLabel>Colored Triangles Layout?</IonLabel>
      <IonToggle onIonChange={(event)=>{setRecolor(event.target.checked)}} checked={recolor} />
    </IonItem>
    <IonItem>
      <IonLabel>Layout excludes Al + Marcin?</IonLabel>
      <IonToggle onIonChange={(event)=>{setUnaffiliated(event.target.checked)}} checked={unaffiliated} />
    </IonItem>
  </div>

  return <LoginGuard>
    <IonPage>
      <IonContent>
        <IonGrid style={{position:"absolute", top:"0", height:"100%", width:"100%", display: "flex", flexDirection: "column"}}>
          <IonRow>
            <IonCol>
              <p style={{textAlign:"left"}}><Breadcrumbs/></p>
            </IonCol>
            <IonCol>
              <h1 style={{textAlign:"center"}}>Marketing&nbsp;Copy - Content&nbsp;Studio</h1>
              <h6 style={{textAlign:"center"}}><IonText color="medium" style={{fontSize:".75em"}}>Powered by My Junior Advisor</IonText></h6>
            </IonCol>
            <IonCol>
              <p style={{textAlign:"right"}}><Logout /></p>
            </IonCol>
          </IonRow>
          <IonRow style={{height:"100%", display: "flex", alignItems: "center", textAlign:"center", overflow: "auto"}}>
            <IonCol size="8" style={{height:"100%", display:"flex", flexDirection:"column", backgroundColor:"#EEEEEE", padding:".5em", overflow: "auto"}}>
              <h2>Preview</h2>
              {pdfProperties?
                pdfViewerComponent
              : <p>Select a PDF on the right to view a preview in this window.</p>}
            </IonCol>
            <IonCol size="4" style={{height:"100%", backgroundColor:"#CCCCCC", padding:".5em", overflow:"auto"}}>
              <AdminToggle>
                <h2>Admin: Upload New File</h2>
                <div>
                  <input type="file" onChange={handleFileChange}/>
                  {file? <>
                    {setPropertiesElements}
                    <br />
                    <IonButton onClick={handleUpload}>Upload</IonButton>
                  </>
                  : <></>}
                </div>
              </AdminToggle>
              {/* https://webdesign.tutsplus.com/tutorials/css-fundamentals-containing-children--net-5664 */}
              <h2>Featured Content</h2>
              <IonItem>
                <IonLabel>Search in Title</IonLabel>
                <IonInput type="text" placeholder="Title" onInput={(e) => setSearchString("" + e.currentTarget.value)} value={searchString} />
              </IonItem>
              <IonItem>
                <IonLabel>Filter By Tags</IonLabel>
                <p>
                {uniqueTags.map((tag, i)=>{
                  return <IonButton color={activeTags[tag]?"primary":"medium"} key={i} onClick={()=>{setActiveTags({...activeTags, [tag]: !activeTags[tag]});}}>{tag}</IonButton>
                })}
                </p>
              </IonItem>
              {loadingListings? <IonSpinner/> : <></>}
              {filteredFilesList.map((fileMetadata, index)=>{
                const cardStyle = {
                  cursor: "pointer",
                } as {[key:string]:string|number};
                if (index===selectedDocumentIndex) {
                  cardStyle.border = "2px solid green";
                }
                return <IonCard style={cardStyle} key={index} onClick={()=>{setSelectedDocumentIndex( (index===selectedDocumentIndex? null : index) )}}>
                  <IonCardHeader><IonCardTitle>{fileMetadata.customMetadata?.title || fileMetadata.name}</IonCardTitle></IonCardHeader>
                  <IonCardContent>
                    <p><b>Tags:</b> {fileMetadata.customMetadata?.tags || "[None]"}</p>
                    <AdminToggle><p><b>Hidden Tags:</b> {fileMetadata.customMetadata?.hiddenTags || "[None]"}</p></AdminToggle>
                    <p><IonText color="medium" style={{fontSize:".75em"}}>(Click to select.)</IonText></p>
                    <AdminToggle>
                      {editView===fileMetadata.fullPath?<>
                        {setPropertiesElements}
                        <IonButton color="success" onClick={(event)=>{event.stopPropagation(); setSelectedDocumentIndex( null );saveMetadata();}}>Admin: Update Metadata</IonButton>
                        <IonButton color="danger" onClick={(event)=>{event.stopPropagation(); setSelectedDocumentIndex( null );handleDelete(fileMetadata.ref)}}>Admin: Delete</IonButton>
                      </>:<>
                      <IonButton color="secondary" onClick={(event)=>{event.stopPropagation(); updateEditView(fileMetadata.fullPath)}}>Admin: Edit Properties</IonButton>
                      </>}
                      
                    </AdminToggle>
                  </IonCardContent>
                </IonCard>
              })}

              {/* <p>Selectable Links to Recent Content will be here. A link at the bottom will switch the tab to "All Content".</p> */}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  </LoginGuard>
}