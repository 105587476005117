import * as s from 'superstruct';

// Define the base schema for all parameters with common properties
const WithId = s.object({
  id: s.string(), // Assuming id is of type string
  displayTitle: s.optional(s.string()),
  parameterDescription: s.optional(s.string()),
});

// Define the schema for percentage cutoff values and their associated label
const PercentageCutoff = s.object({
  cutoff: s.refine(s.number(), 'Cutoff', value => value >= 0 && value <= 100),
  label: s.string(),
});

// Custom validation for ordered list of PercentageCutoff with a literal type
const PercentageCutoffList = s.object({
  type: s.literal('percentageCutoffList'),
  data: s.refine(s.array(PercentageCutoff), 'OrderedCutoffList', value => {
    for (let i = 1; i < value.length; i++) {
      if (value[i].cutoff <= value[i - 1].cutoff) {
        return false;
      }
    }
    return true;
  }),
});

// Define a similar schema for general cutoff values without the percentage constraint
const Cutoff = s.object({
  cutoff: s.number(),
  label: s.string(),
});

// Custom validation for ordered list of general Cutoff with a literal type
const CutoffList = s.object({
  type: s.literal('cutoffList'),
  data: s.refine(s.array(Cutoff), 'OrderedCutoffList', value => {
    for (let i = 1; i < value.length; i++) {
      if (value[i].cutoff <= value[i - 1].cutoff) {
        return false;
      }
    }
    return true;
  }),
});

// Define the schema for recommendations
const Recommendation = s.object({
  keys: s.record(s.string(), s.string()),
  lightColor: s.enums(['green', 'yellow', 'red']),
  tags: s.array(s.string()),
  description: s.string(),
});
// TODO: Infer / share
export type LightColor = "green" | "yellow" | "red";

// Define the schema for a table of recommendations
const RecommendationsTable = s.object({
  type: s.literal('recommendationsTable'),
  data: s.array(Recommendation),
});

// Define the union schema including the extended schemas
const Parameter = s.union([
  s.assign(WithId, PercentageCutoffList),
  s.assign(WithId, CutoffList),
  s.assign(WithId, RecommendationsTable),
]);

// Export schemas and types
export { PercentageCutoff, PercentageCutoffList, Cutoff, CutoffList, Recommendation, RecommendationsTable, Parameter };

// Export TypeScript types inferred from the schema
export type PercentageCutoffType = s.Infer<typeof PercentageCutoff>;
export type PercentageCutoffListType = s.Infer<typeof PercentageCutoffList>;
export type CutoffType = s.Infer<typeof Cutoff>;
export type CutoffListType = s.Infer<typeof CutoffList>;
export type RecommendationType = s.Infer<typeof Recommendation>;
export type RecommendationsTableType = s.Infer<typeof RecommendationsTable>;
export type ParameterType = s.Infer<typeof Parameter>;
