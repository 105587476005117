import React, { useContext, useEffect, useState } from "react";
import { functions } from "../App";
import { httpsCallable } from "firebase/functions";
import { useAuth } from "./AuthProvider";
import { DEFAULT_PERMISSIONS, Permissions } from "common"
import { Client } from "./data-types";

export const DataContext = React.createContext({
  permissions: DEFAULT_PERMISSIONS, 
  loadingPermissions: false,
  marketingProfile: {} as any, 
  loadingMarketingProfile: false,
  uploadMarketingProfile: (data:any)=>{}, // return {} as Promise<any>
  clientList: [] as Client[],
  updateClientList: (newClientList: Client[])=>{},
}); //TODO: Could swap out signup and login for 'any'.

export function useData() {
  return useContext(DataContext);
}

export const DataProvider: React.FC<{children: React.ReactNode}> = ({children, ...props}) => {
  const {currentUser} = useAuth();
  const [permissions, setPermissions] = useState(DEFAULT_PERMISSIONS);
  const [loadingPermissions, setLoadingPermissions] = useState(true);

  const [marketingProfile, setMarketingProfile] = useState({} as any);
  const [loadingMarketingProfile, setLoadingMarketingProfile] = useState(false);

  const [clientList, setClientList] = useState([] as Client[]);
  const clientListPropName = "clientList"; // Used in saving and loading.
  const updateClientList = (newClientList: Client[])=>{
    setClientList(newClientList);
    const data = {} as {[key:string]: any};
    data[clientListPropName] = JSON.stringify(newClientList);
    createUpdateOwnedRecord({collectionName: "client lists", record: data});
  }

  const fetchPermissions = httpsCallable(functions, "fetchPermissions");
  const createUpdateOwnedRecord = httpsCallable(functions, "createUpdateOwnedRecord");
  const fetchOwnedRecords = httpsCallable(functions, "fetchOwnedRecords");

  const uploadMarketingProfile = (data: any) => {
    // Fetch Marketing Profile
    setLoadingMarketingProfile(true);
    createUpdateOwnedRecord({collectionName: "marketing profiles", record: data}).then((result)=>{
      setMarketingProfile(result.data|| {});
    }).finally(()=>{
      setLoadingMarketingProfile(false);
    });

  };

  useEffect(() => {
    if (!currentUser?.uid) {
      return;
    }

    // Fetch Permissions
    setLoadingPermissions(true);
    fetchPermissions().then((result)=>{
      setPermissions((result.data as Permissions));
    }).finally(()=>{
      setLoadingPermissions(false);
    });

    // Fetch Marketing Profile
    setLoadingMarketingProfile(true);
    fetchOwnedRecords({collectionName: "marketing profiles"}).then((result)=>{
      setMarketingProfile((result.data as any[])[0] || {});
    }).finally(()=>{
      setLoadingMarketingProfile(false);
    });

    // Fetch Client List
    // For now, just store as ownedRecord with a named property.
    fetchOwnedRecords({collectionName: "client lists"}).then((result)=>{
      const record = (result.data as any[])[0] || {};
      const newClientList = JSON.parse(record[clientListPropName] || "[]");
      setClientList(newClientList);
    })
  }, [currentUser]);

  return ( 
    <DataContext.Provider value={{permissions, loadingPermissions, marketingProfile, loadingMarketingProfile, uploadMarketingProfile, clientList, updateClientList}}>
      {/* {!loading? children: <IonSpinner/>} */}
      {children}
    </DataContext.Provider>
   );
}

export default DataProvider;