import { IonApp, setupIonicReact } from '@ionic/react';
import {initializeApp} from 'firebase/app';
import {connectFunctionsEmulator, getFunctions, httpsCallable} from 'firebase/functions';
import { getStorage } from "firebase/storage";

import { firebaseConfig } from './firebaseConfig';

import { AppRouter } from './AppRouter';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/global.css';
import AuthProvider from './services/AuthProvider';
import DataProvider from './services/DataProvider';
import { RecordingsProvider } from './services/RecordingsProvider';
import { QueueProvider } from './fullstack-utilities/task-queue-provider';
import { useEffect, useState } from 'react';
import { getFirestore } from 'firebase/firestore';
import { ClientsProvider } from './services/client-records/clients-provider';
import { ParametersProvider } from './services/parameters/parameters-provider';


setupIonicReact();

const firebaseApp = initializeApp(firebaseConfig);
const functions = getFunctions(firebaseApp);
//Note: Code to connect (and test) connection to functions emulator should be called in main.tsx
const polyFillFunctions = async ()=>{
  if (window.location.hostname.toLowerCase() === 'localhost') {
    // connectFunctionsEmulator(functions, 'localhost', 5001); // TODO: Replace with below when below is working.
    try {
      const response = await fetch('http://localhost:5001', { mode: 'no-cors' });
      if (response) {
        connectFunctionsEmulator(functions, 'localhost', 5001);
        console.log('Connected to Firebase Functions emulator.');
      } else {
        console.warn('Functions emulator not detected, using production.');
      }
    } catch (error) {
      console.error('Failed to connect to Functions emulator:', error);
      // Proceed with production functions as fallback
      console.log('Using production functions calls as fallback.');
    }
  }
}
export const transcribeAudio = httpsCallable(functions, 'transcribeAudioFromStorage', {timeout: 540*1000});
export const summarizeTranscript = httpsCallable(functions, 'summarizeAudioFromStorage', {timeout: 540*1000});
export const proxyChatCall = httpsCallable(functions, 'proxyChatCall', {timeout: 540*1000});
export const proxyEmbeddingsCall = httpsCallable(functions, 'proxyEmbeddingsCall', {timeout: 540*1000});
const storage = getStorage(firebaseApp);
const firestore = getFirestore(firebaseApp);
export {firebaseApp, functions, storage, firestore}//, firebaseFunctions}; // Since it's pretty unimaginable to be running two firebase APIs in one frontend, this wil just be a global export, not a context provider.
// However, uses of the object (auth, firestore) will have related context providers.

const App: React.FC = () => {
  const [functionsConnecting, setFunctionsConnecting] = useState(true);
  useEffect(()=>{
    polyFillFunctions().finally(()=>{setFunctionsConnecting(false)});
  }, [])

  return (!functionsConnecting?
  <IonApp>
    <QueueProvider>
      <AuthProvider>
        <ParametersProvider>
          <ClientsProvider>
            <DataProvider>
              <RecordingsProvider>
                <AppRouter />
              </RecordingsProvider>
            </DataProvider>
          </ClientsProvider>
        </ParametersProvider>
      </AuthProvider>
    </QueueProvider>
  </IonApp>
  : <></>
  )
};

export default App;
