import React, { createContext, useContext, useState, useCallback, ReactNode, useRef } from 'react';
import { MaxQueue } from './priority-queue';

type Task = () => Promise<void>;
type NamedTask = {
  task: Task;
  key?: string;
}
type Status = "pending" | "running" | "finished" | "error";

interface QueueContextType {
  addToQueue: (task: Task, queueName?: string, key?: string, priority?: number) => void;
  checkStatus: (queueName: string, key:string) => Status;
}
// TODO: Priority currently requires client calls to manually differentiate at task creation time.

const QueueContext = createContext<QueueContextType | undefined>(undefined);

export const QueueProvider: React.FC<{children: ReactNode}> = ({ children }) => {
  // const [queue, setQueue] = useState<Task[]>([]);
  const queueRef = useRef<{[queueName: string]: MaxQueue<NamedTask>}>({});
  // const [isProcessing, setIsProcessing] = useState(false);
  const isProcessingRef = useRef<{[queueName: string]: boolean}>({});
  const processStatusRef = useRef<{[queueName: string]: {[key: string]: Status}}>({});

  const processQueue = useCallback(async (queueName: string) => {
    console.log("Processing started.");
    if (isProcessingRef.current[queueName] || queueRef.current[queueName].length() === 0) return;
    isProcessingRef.current[queueName] = true;

    while (queueRef.current[queueName].length() > 0) {
      const {task: currentTask, key} = queueRef.current[queueName].remove()!.element;
      try {
        if (key) {
          // Check to see if the process was already executed. If so, skip processing.
          // TODO: Maybe consider caching and returning the result somehow?
          if (["finished", "running"].includes(processStatusRef.current[queueName][key])) {
            continue; // Move on to the next task in the queue.
          }

          processStatusRef.current[queueName][key] = "running";
        }
        await currentTask().then(()=>{
          if (key) {
            processStatusRef.current[queueName][key] = "finished";
          }
        });
      } catch (error) {
        console.error('Task error:', error);
        // TODO: more robust error handling - interacting with promise rejection?
        if (key) {
          processStatusRef.current[queueName][key] = "error";
        }
      }
    }

    isProcessingRef.current[queueName] = false;
  }, [queueRef, isProcessingRef, processStatusRef]);

  const addToQueue = useCallback((task: Task, queueName = "", key = "", priority=0) => {
    console.log(`Queue added to. Processing: ${isProcessingRef.current}`);
    queueRef.current[queueName] ||= new MaxQueue<NamedTask>();
    if (key) {
      processStatusRef.current[queueName] ||= {};
      processStatusRef.current[queueName][key] = "pending";
    }
    queueRef.current[queueName].add({task, key}, priority);
    if (!isProcessingRef.current[queueName]) processQueue(queueName);
  }, [processQueue, queueRef, isProcessingRef]);

  const checkStatus = useCallback((queueName: string, key: string)=>{
    return processStatusRef.current[queueName]?.[key]
  }, [processStatusRef])

  return (
    <QueueContext.Provider value={{ addToQueue, checkStatus }}>
      {children}
    </QueueContext.Provider>
  );
};

export const useQueue = () => {
  const context = useContext(QueueContext);
  if (!context) {
    throw new Error('useQueue must be used within a QueueProvider');
  }
  return context;
};