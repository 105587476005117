import React, { useState, useEffect } from 'react';
import { IonGrid, IonRow, IonCol } from '@ionic/react';

// Define constants
const emPixels = 16; // Estimated
const minColWidth = 30; // in em

// Utility function to compute the number of showable columns
const computeShowableColumns = (width: number) => Math.max(1, Math.floor(width / (minColWidth * emPixels)));

// Define the MultiColumnContent component
export interface TabContent {
  title: string;
  content: JSX.Element;
}

export const MultiColumnContent: React.FC<{ tabs: TabContent[] }> = ({ tabs }) => {
  const [showableColumns, setShowableColumns] = useState<number>(computeShowableColumns(window.innerWidth));
  const [visibleStartIndex, setVisibleStartIndex] = useState<number>(0);
  const [activeTab, setActiveTab] = useState<number>(0);

  const handleResize = () => {
    setShowableColumns(computeShowableColumns(window.innerWidth));
  };

  const handleTabClick = (index: number) => {
    if (index < visibleStartIndex) {
      setVisibleStartIndex(index); // Slide to the left
    } else if (index >= visibleStartIndex + showableColumns) {
      setVisibleStartIndex(index - showableColumns + 1); // Slide to the right
    }
    setActiveTab(index);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    setVisibleStartIndex(0); // Reset visible start index on initial render or window resize
  }, [showableColumns, tabs.length]);

  return (
    <div>
      {showableColumns < tabs.length && (
        <div style={{ display: 'flex', justifyContent: 'center', padding: '0 1em', flexShrink: 0, borderBottom: '2px solid #000' }}>
          <div style={{ overflowX: 'auto', whiteSpace: 'nowrap'}}>
            {tabs.map((tab, index) => {
              const isVisible = index >= visibleStartIndex && index < visibleStartIndex + showableColumns;
              return (
                <div
                  key={index}
                  onClick={() => handleTabClick(index)}
                  style={{
                    cursor: 'pointer',
                    padding: '1em',
                    borderTop: isVisible ? '2px solid black' : '2px solid transparent',
                    borderTopLeftRadius: '8px',
                    borderTopRightRadius: '8px',
                    backgroundColor: isVisible ? '#e0e0e0' : 'transparent', // Highlight visible tabs
                    display: 'inline-block',
                    marginRight: index < tabs.length - 1 ? '0.5em' : '0',
                  }}
                >
                  {tab.title}
                </div>
              );
            })}
          </div>
        </div>
      )}
      <IonGrid style={{ height: '100%' }}>
        <IonRow style={{ height: '100%' }}>
          {tabs.slice(visibleStartIndex, visibleStartIndex + showableColumns).map((tab, index) => (
            <IonCol key={index} style={{ height: '100%' }}>
              <h2>{tab.title}</h2>
              {tab.content}
            </IonCol>
          ))}
        </IonRow>
      </IonGrid>
    </div>
  );
};

export default MultiColumnContent;
