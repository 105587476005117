import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonItem, IonLabel } from "@ionic/react";
import { ReportRecord } from "../../services/client-records/clients-provider";

export const ReportSummaryCard: React.FC<{report: ReportRecord}> = ({report}) => {
  let status = "not started";
  if (report.accountsData) {
    status = "holdings table extracted";
  }
  if (report.morningstarData) {
    status = "analysis complete; insights ready"
  }
  return <IonCard style={{ marginBottom: "1em" }} routerLink={`/clients/${report.clientId}/reports/${report.id}`}>
    <IonCardHeader>
      <IonCardTitle>{report.clientName}</IonCardTitle>
    </IonCardHeader>
    <IonCardContent>
      <IonItem>
        <IonLabel>
          <h2>Report Date</h2>
          <p>{"Started: " + (report?.createdAt?.toLocaleString() || "Report in Progress")}</p>
        </IonLabel>
      </IonItem>
      <IonItem>
        <IonLabel>
          <h2>Status</h2>
          <p>{status}</p>
        </IonLabel>
      </IonItem>
    </IonCardContent>
  </IonCard>
}