import { IonReactRouter } from "@ionic/react-router"
import { Redirect, Route, Switch } from "react-router"
import { Link } from "react-router-dom"
import Home from "./pages/Home"
import InfoStub from "./pages/InfoStub"
import { LoginGuard } from "./pages/LoginGuard"
import { MarketingCopy } from "./pages/MarketingCopy"
import { MyInfo } from "./pages/MyInfo"
import { Splash } from "./pages/Splash"
import { useAuth } from "./services/AuthProvider"
// import { stripeTestingConfig } from "./stripeTestingConfig"
import { SmartNotes } from "./components/SmartNotes"
import Recorder from "./components/Recorder"
import { RecordingInspector } from "./components/RecordingInspector"
import { RecordingsManager } from "./components/RecordingsManager"
import IngestReport from "./components/OLD report-processing/IngestReport"
import { Mockup } from "./pages/Mockup"
import PracticeDashboard from "./pages/recommendations/practice-dashboard"
import ClientDetails from "./pages/recommendations/client-details"
import ReportProcessor from "./pages/recommendations/report-processor"
import { AdminToggle } from "./components/AdminToggle"
import AdminParameterEditor from "./services/parameters/admin-parameter-editor"
import { IonSpinner } from "@ionic/react"
import { useData } from "./services/DataProvider"

// For the stripe pricing table:
// https://zenn.dev/hideokamoto/scraps/c588966247b1f9
// Alternate: https://stackoverflow.com/questions/73468977/property-stripe-pricing-table-does-not-exist-on-type-jsx-intrinsicelements
declare global {
  export namespace JSX {
    interface IntrinsicElements {
      'stripe-pricing-table': {
        key: string;
        'pricing-table-id': string;
        'publishable-key': string;
        'client-reference-id'?: string;
      };
    }
  }
}

export const AppRouter: React.FC = () => {
  // NOTE: IonRouterOutlet seems to cause issues on re-render when renders change based on state variables. E.g. login and logout. Using Switch instead.
  const { currentUser } = useAuth();
  const { permissions, loadingPermissions } = useData();

  const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';

  return <>
    <IonReactRouter>
      <Switch>

        <Route exact path="/splash">
          <Splash />
        </Route>

        <Route exact path="/about">
          <InfoStub title="About">
            <p>My Junior Advisor facilitates analysis and recommendations to ensure a high quality of service for financial clients. Most advisors will want to start by examining the marketing copy available to be whitelabeled.</p>
            <br/>
            <p>Let My Junior Advisor handle the ordinary,<br/>so your service can be extraordinary.</p>
            <br/>
            <br/>
            <p><Link to="/privacy">Privacy Policy</Link> &nbsp; <Link to="/terms">Terms and Conditions</Link></p>
          </InfoStub>
        </Route>

        <Route exact path="/pricing">
          <InfoStub title="Pricing">
            {/* <p>There are three levels of access to functionality in My Junior Advisor.</p>
            <br/>
            <p>Platform preview access is free, and only requires creating an account on this site.</p>
            <br/>
            <p>Media + Content Customization requires a subscription to the platform, and includes access to all timely literature, whitelabeled copy, training videos, and update briefings. Pricing scales with number of seats, and exact pricing can be explored in preview mode on the <Link to="/my-info">Profile Management Page</Link>.</p>
            <br/>
            <p>AI-powered functionality is available on a pay-per-use basis (pricing is listed on each page), and cannot be accessed without a platform subscription.</p> */}
            <p><a href="mailto:jrcumplido@icloud.com">Contact us</a> to get access to the platform and to discuss pricing.</p>
          </InfoStub>
        </Route>

        <Route exact path="/privacy">
          <InfoStub title="Privacy Policy">
            <div style={{textAlign:"justify"}}>
              <p>At My Junior Advisor, we take your privacy and the security of your personal information seriously. This privacy policy describes how we collect, use, and protect your personal information when you use our website, myjunioradvisor.com.</p>

              <p><b>1. Information we collect</b></p>
              <p>We collect personal information from you when you register on our site, place an order, subscribe to our newsletter, respond to a survey, fill out a form, or otherwise use our site. This information may include your name, email address, phone number, mailing address, payment information, and other details. Additionally, if you upload financial information such as investment amounts and account holder information, or if you upload video or audio recordings, this information may be collected and stored on our servers for the purpose of providing our service.</p>

              <p><b>2. How we use your information</b></p>
              <p>We use your personal information to provide you with services and products you request, to process your payments, to communicate with you, to improve our website and services, and for other legitimate business purposes. We may also use your information to send you promotional or marketing materials, which you can opt out of at any time.</p>

              <p><b>3. How we protect your information</b></p>
              <p>We implement a variety of security measures to protect your personal information, including secure servers and SSL encryption technology. We also limit access to your information to only those employees and contractors who need it to provide you with our services.</p>

              <p><b>4. Disclosure of information to third parties</b></p>
              <p>We do not sell, trade, or otherwise transfer your personal information to third parties unless we provide you with advance notice. This does not include trusted third parties who assist us in operating our website, conducting our business, or servicing you, as long as those parties agree to keep your information confidential. We may also release your information when we believe it is appropriate to comply with the law, enforce our site policies, or protect ours or others' rights, property, or safety.</p>

              {/* Note that DeepGram does not store audio nor transcription data after returning its results. */}

              <p><b>5. Third-party links</b></p>
              <p>Our website may include links to third-party sites that are not under our control. These third-party sites have their own privacy policies, which you should review. We have no responsibility or liability for the content and activities of these linked sites.</p>

              <p><b>6. Use of cookies</b></p>
              <p>We use cookies and other tracking technologies to enhance your experience on our website and to personalize your visit. You can choose to have your computer warn you each time a cookie is being sent, or you can choose to turn off all cookies. If you turn cookies off, some features of our site may not function properly.</p>

              <p><b>7. Changes to our privacy policy</b></p>
              <p>We may revise this privacy policy from time to time, and we will post the updated policy on our website. Your continued use of our website after we make changes is deemed to be acceptance of those changes.</p>

              <p><b>8. Contacting us</b></p>
              <p>If you have any questions about this privacy policy or our website, please <a href="mailto:jrcumplido@icloud.com">contact us</a>.</p>
            </div>
            
          </InfoStub>
        </Route>

        <Route exact path="/terms">
          <InfoStub title="Terms and Conditions of Use">
            <h2>My Junior Advisor Content Creator</h2>
            <p style={{textAlign:"justify"}}>
              <ol>
                <li>Introduction: MJA Content Creator (hereinafter referred to as "Copywriter") is a marketing and copywrite company that creates and licenses out white-labeled content (hereinafter referred to as "Content"). The Client (hereinafter referred to as "Client") wishes to use the Content for their own purposes, and both parties agree to the terms outlined in this disclosure.</li>
                <li>Ownership of Content: The Copywriter is the sole owner of all the Content created, and retains full ownership rights to the Content, including but not limited to, all copyrights, trademarks, and intellectual property rights. The Client acknowledges that they have no ownership rights to the Content and agree not to take any action that would infringe on the Copywriter's rights or reputation.</li>
                <li>License Grant: The Client is granted a non-exclusive, non-transferable license to use the Content for their own purposes. The Client agrees to not re-brand, edit, or otherwise modify the Content in a way that is outside of the scope of the editing tools provided. Under no circumstances is the Client given the right to alter the content in a way that that would change its meaning or context. The license granted to the Client is for a limited time period, as agreed upon by both parties, and may be terminated at any time by either party with written notice.</li>
                <li>Proprietary Information: The Client acknowledges that the Content may contain proprietary information that is confidential and agrees to use the Content only for the purpose for which it was intended. The Client agrees not to disclose, distribute, or share the Content with any third-party without the prior written consent of the Copywriter.</li>
                <li>Attribution: The Client agrees to not claim the Content as their own or represent themselves as the author of the Content outside of the scope of the provided white labeled template. Proper attribution to the Copywriter in the disclosure section of all content must remain. in any publication or use of the Content.</li>
                <li>Promotional Use: The Copywriter reserves the right to use the Content for their own promotional purposes, including but not limited to, showcasing their work on their website and portfolio.</li>
                <li>Accuracy of Content: The Copywriter is not responsible for any errors or inaccuracies in the Content, and the Client is responsible for thoroughly reviewing and verifying the accuracy of the Content before using it.</li>
                <li>Termination of License: The Client agrees to immediately stop using the Content upon termination of the license granted in this disclosure. The Client agrees to destroy or return any physical or electronic copies of the Content in their possession upon termination of the license.</li>
                <li>Governing Law: This disclosure is governed by the laws of the jurisdiction in which the Copywriter resides, and any disputes arising from this agreement will be resolved through binding arbitration.</li>
                <li>Entire Agreement: This disclosure constitutes the entire agreement between the parties and supersedes all prior negotiations, understandings, and agreements between the parties, whether written or oral. Any modifications to this disclosure must be in writing and signed by both parties.</li>
              </ol>
            </p>
            <p>By accepting the white-labeled content agreement, the Client acknowledges and agrees to the terms outlined in this disclosure.</p>
          </InfoStub>
        </Route>

        <Route exact path="/home">
          <LoginGuard><Home /></LoginGuard>
        </Route>

        <Route exact path="/marketing-copy" component={MarketingCopy}/>

        {/* Not sure if the above could have been combined with this one. */}
        <Route exact path="/marketing-copy/:tags" component={MarketingCopy}/>

        <Route exact path="/smart-notes/record">
          <LoginGuard>
            <InfoStub title="Record">
              <Recorder/>
            </InfoStub>
          </LoginGuard>
        </Route>

        <Route exact path="/smart-notes/manage/:date" component={RecordingInspector}>
          {/* RecordingInspector uses history, path, match, so is passed as a component, not a child. There is a login guard around the component. */}
        </Route>

        {/* <Route exact path="/smart-notes/manage">
          <LoginGuard>
            <InfoStub title="Manage">
              <RecordingsManager/>
            </InfoStub>
          </LoginGuard>
        </Route> */}

        {/* <Route exact path="/smart-notes/insights">
          <LoginGuard>
            <InfoStub title="Insights">
              <Insights/>
            </InfoStub>
          </LoginGuard>
        </Route> */}

        <Route exact path="/smart-notes">
          <LoginGuard>
            <InfoStub title="Smart&nbsp;Notes">
              {/* <SmartNotes/> */}
              <RecordingsManager/>
            </InfoStub>
          </LoginGuard>
        </Route>

        <Route exact path="/clients">
          <LoginGuard>
            {/* <InfoStub title="AI for Advising [Coming&nbsp;Soon]">
              <p>Perform detailed analyses of client holdings from a scanned PDF, and provide recommendations that account for client risk tolerance and financial goals. Automatically generate a summary report that charts geographic and asset class allocations.</p>
            </InfoStub> */}
            {/* <IngestReport/> */}
            <InfoStub title="AI for Advising [UI Mockup]">
              <PracticeDashboard/>
            </InfoStub>
          </LoginGuard>
        </Route>

        {/* ClientDetails renders the loginguard and infostub. */}
        <Route exact path="/clients/:clientID" component={ClientDetails}/>
        
        <Route exact path="/clients/:clientID/reports/:reportID" component={ReportProcessor}/>

        <Route exact path="/my-info">
          <LoginGuard>
            <MyInfo/>
          </LoginGuard>
        </Route>

        <Route exact path="/admin">
          <LoginGuard>
            <AdminToggle nonAdminComponent={loadingPermissions? <IonSpinner/>: <Redirect to="/home"/>}>
              <InfoStub title="Admin-Editable Parameters">
                <AdminParameterEditor/>
              </InfoStub>
            </AdminToggle>
          </LoginGuard>
        </Route>

        {/*
        <Route exact path="/billing">
          <LoginGuard>
            <InfoStub title="Billing">
            {isLocalhost?
              <stripe-pricing-table
                key="subscription pricing table"
                pricing-table-id={stripeTestingConfig.subscriptionPricingTableID}
                publishable-key={stripeTestingConfig.testingPublicKey}
                client-reference-id={currentUser?.uid || ""}
                customer-email={currentUser?.email || ""}
              />
            :
              <stripe-pricing-table
                key="subscription pricing table"
                pricing-table-id="prctbl_1Mu0CPK0zEuV7WczkLVlraOv"
                publishable-key="pk_live_51Jz688K0zEuV7Wcz7TYTBVHGUV8txZP7bVXYLAWyA1z8iP0t4qid3MhYDtzf3fJ24nK8YLeYKS83NZvdzQ3nr9ux00EH0Gp0t3"
                client-reference-id={currentUser?.uid || ""}
                customer-email={currentUser?.email || ""}
              />
            }
            </InfoStub>
          </LoginGuard>
        </Route>
        */}

        {/* TODO: Different redirect page? Maybe to my-info? */}
        <Route exact path="/billing-confirmation">
          <LoginGuard>
            <InfoStub title="Purchase Complete">
              <p>Your purchase has been completed. You can visit the billing dashboard to make adjustments at any time.</p>
            </InfoStub>
          </LoginGuard>
        </Route>

        <Route exact path="/mockup">
          <LoginGuard>
            <InfoStub title="Mockup">
              <Mockup/>
            </InfoStub>
          </LoginGuard>
        </Route>

        <Route exact path="/help">
          <InfoStub title="Help">
            <p>My Junior Advisor is a software platform that empowers you to provide financial advising services at the highest quality to more clients, more responsively, by automating key tasks.</p>
            <p>Struggling to find what you're looking for? Feel free to <a href="mailto:jrcumplido@icloud.com">contact support</a>.</p>
          </InfoStub>
        </Route>

        <Route exact path="/">
          <Redirect to="/splash" />
        </Route>

        <Route>
          <InfoStub title="404 - Page&nbsp;not&nbsp;Found">
            <p>This is probably our mistake, but somehow you wound up at a web address we don't recognize. Sorry about that! Feel free to try again, or use the back button on your browser to get back to where you came from. If the problem persists, feel free to <a href="mailto:jrcumplido@icloud.com">contact support</a>.</p>
          </InfoStub>
        </Route>

      </Switch>
    </IonReactRouter>
  </>
}