import React from 'react';

interface ThreeBarChartProps {
    greenNumber: number;
    blueNumber: number;
    redNumber: number;
}

const ThreeBarChart: React.FC<ThreeBarChartProps> = ({ greenNumber, blueNumber, redNumber }) => {
    const data = [greenNumber, blueNumber, redNumber];
    const total = Math.max(data.reduce((sum, value) => sum + value, 0), 1); // Calculate total to find proportions
    const percentages = data.map(value => (value / total) * 100); // Calculate percentage width of each segment

    return (
        <div style={{ display: 'flex', width: '100%', height: '1rem', backgroundColor: '#ddd' }}>
            {percentages.map((percentage, index) => (
                <div
                    key={index}
                    style={{
                        width: `${percentage}%`,
                        backgroundColor: index === 0 ? 'rgba(75, 192, 192, 0.8)' : // Green
                                          index === 1 ? 'rgba(54, 162, 235, 0.8)' : // Blue
                                                       'rgba(255, 99, 132, 0.8)', // Red
                        height: '100%'
                    }}
                />
            ))}
        </div>
    );
};

export default ThreeBarChart;