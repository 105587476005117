import React, { useContext, useState, useEffect, useCallback, ReactNode } from 'react';
import { useAuth } from './AuthProvider';
import { FullMetadata, getMetadata, listAll, ref } from "firebase/storage";
import { functions, storage, summarizeTranscript, transcribeAudio } from "../App";
import { useQueue } from '../fullstack-utilities/task-queue-provider';
import { httpsCallable } from 'firebase/functions';

type RecordingsContextType = {
  filesList: FullMetadata[];
  loadingListings: boolean;
  refreshData: () => void;
  metadataComplete: (record: FullMetadata) => boolean;
};

const RecordingsContext = React.createContext<RecordingsContextType | undefined>(undefined);

export const useRecordings = () => {
  const context = useContext(RecordingsContext);
  if (context === undefined) {
    throw new Error('useRecordings must be used within a RecordingsProvider');
  }
  return context;
};

export const RecordingsProvider: React.FC<{children: ReactNode}> = ({ children }) => {
  const { currentUser } = useAuth();
  // let uid = currentUser?.uid || "NO_USER_IDENTIFIED";
  let uid = currentUser?.uid;
  // Admin override; debugging Al's recordings.
  // if (uid === "gQ6uSdk0sNSWwJQL1oPEJFK6Q5B2" || uid === "OHgrHEoqvQQToBQl49BTeyDqsNg1") {
  //   uid = "aWdbYqMOYSSyAWBbtjUUXf3dsJt2";
  // }

  const [loadingListings, setLoadingListings] = useState(false);
  const [filesList, setFilesList] = useState([] as FullMetadata[]);

  const fetchMetadata = useCallback(async () => {
    if (!uid) {
      return;
    }
    
    setLoadingListings(true);
    const storageRef = ref(storage, `user/${uid}/audio`);
    listAll(storageRef)
      .then(function (result) {
        const promises = result.items.map((ref) => {
          return getMetadata(ref);
        });
        Promise.all(promises).then((metadata) => {
          const sortedMetadata = metadata.sort((a, b) => {
            return (Number.parseInt(b.customMetadata?.date || "") - Number.parseInt(a.customMetadata?.date || ""));
          });
          setFilesList(sortedMetadata);
        });
      })
      .catch(function (error) {
        console.error("Error fetching metadata:", error);
      })
      .finally(() => {
        setLoadingListings(false);
      });
  }, [uid]);

  const metadataComplete = useCallback((record: FullMetadata)=>{
    const isComplete = (record.customMetadata?.summary?.length || 0) > 0 && ((record.customMetadata?.labeledUtterances?.length || 0) > 0);
    return isComplete; // No need to check speakers list, since they will have been set in order to generate the summary.
  }, []);

  useEffect(() => {
    fetchMetadata();
  }, [fetchMetadata]);

  // ===== Auto-queue transcription of recordings that are untranscribed.
  const {addToQueue} = useQueue();
  // Triggers after filesList is updated.
  useEffect(()=>{
    

    filesList.forEach((file)=>{
      // Preventing Queue redundancies is handled by the useQueue.
      // As written, this would requeue remaining files on every update, but the tasks will be skipped if they are repeated.
      // Checks for queue status were added to make logs more readable.
      if ((file.customMetadata?.labeledUtterances?.length || 0) === 0) {
        console.log(`Queueing Transcription for ${file.fullPath}.`)
        addToQueue(async ()=>{
          console.log(`Beginning Transcription for ${file.fullPath}.`)
          await transcribeAudio({ path: file.fullPath }).then((result)=>{
            console.log(`Completed Transcription for ${file.fullPath}.`)
            fetchMetadata(); // TODO: Better data update trigger (no race conditions / staggered updates).
          }).catch((reason)=>{
            console.error(`Transcription Failed for ${file.fullPath} for ${reason}`);
          })
        }, "transcription", file.fullPath)
      } else if ((file.customMetadata?.summary?.length || 0) === 0) {
        console.log(`Queueing Summarization for ${file.fullPath}.`)
        addToQueue(async ()=>{
          console.log(`Beginning Summarization for ${file.fullPath}.`)
          await summarizeTranscript({ path: file.fullPath }).then((result)=>{
            console.log(`Completed Summarization for ${file.fullPath}.`)
            fetchMetadata(); // TODO: Better data update trigger (no race conditions / staggered updates).
          }).catch((reason)=>{
            console.error(`Summarization Failed for ${file.fullPath} for ${reason}`);
          })
        }, "summarization", file.fullPath)
      }
    })
  }, [filesList]);

  return (
    <RecordingsContext.Provider
      value={{
        filesList,
        loadingListings,
        refreshData: fetchMetadata,
        metadataComplete,
      }}
    >
      {children}
    </RecordingsContext.Provider>
  );
};