import React, { useState, useCallback, useEffect } from 'react';
import {
  IonButton,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonTextarea,
  IonText,
  IonSpinner,
  IonIcon,
  IonSelect,
  IonSelectOption,
} from '@ionic/react';
import { save, add, trash } from 'ionicons/icons';
import { useParameters } from './parameters-provider';
import {
  PercentageCutoffListType,
  CutoffListType,
  RecommendationsTableType,
  ParameterType
} from './parameters-schema';
import { debounce } from 'lodash';

const AdminParameterEditor: React.FC = () => {
  const { parameters, updateParameter, loadingParameters } = useParameters();
  const [selectedParameter, setSelectedParameter] = useState<ParameterType | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (parameters && parameters.length > 0) {
      setSelectedParameter(parameters[0]);
    }
  }, [parameters]);

  const handleSelectParameter = useCallback((id: string) => {
    const param = parameters?.find(p => p.id === id) || null;
    setSelectedParameter(param);
  }, [parameters]);

  const handleInputChange = useCallback((path: string, value: any) => {
    if (selectedParameter) {
      const newParam = { ...selectedParameter };
      const keys = path.split('.');
      let temp: any = newParam;
      keys.forEach((key, index) => {
        if (index === keys.length - 1) {
          temp[key] = value;
        } else {
          temp = temp[key];
        }
      });
      setSelectedParameter(newParam);
    }
  }, [selectedParameter]);

  const handleSubmit = async () => {
    if (!selectedParameter || !selectedParameter.id) return;
    try {
      await updateParameter(selectedParameter.id, selectedParameter);
      setError(null);
    } catch (e) {
      setError((e as Error).message);
    }
  };

  if (loadingParameters) return <IonSpinner />;

  return (
    <>
      <div>
        <IonLabel>Parameter Table to Edit:</IonLabel>
        <select onChange={(e) => handleSelectParameter(e.target.value)} value={selectedParameter?.id || ''}>
          {parameters?.map((param) => (
            <option key={param.id} value={param.id}>
              {param.displayTitle || param.type}
            </option>
          ))}
        </select>
        {error && <IonText color="danger">{error}</IonText>}
        <IonButton onClick={handleSubmit}>
          <IonIcon slot="start" icon={save} />
          Save Parameters
        </IonButton>
      </div>

      {selectedParameter && (
        <>
          <h3>{selectedParameter.displayTitle}</h3>
          <p>{selectedParameter.parameterDescription}</p>
          {selectedParameter.type === 'percentageCutoffList' && (
            <CutoffListEditor
              data={selectedParameter.data}
              handleInputChange={handleInputChange}
            />
          )}
          {selectedParameter.type === 'cutoffList' && (
            <CutoffListEditor
              data={selectedParameter.data}
              handleInputChange={handleInputChange}
            />
          )}
          {selectedParameter.type === 'recommendationsTable' && (
            <RecommendationsTableEditor
              data={selectedParameter.data}
              handleInputChange={handleInputChange}
            />
          )}
        </>
      )}
    </>
  );
};

const CutoffListEditor: React.FC<{
  data: CutoffListType['data'] | PercentageCutoffListType['data'];
  handleInputChange: (path: string, value: any) => void;
}> = ({ data, handleInputChange }) => {
  const [localData, setLocalData] = useState(data);

  const handleAddCutoff = () => {
    const newCutoff = { cutoff: 0, label: '' };
    const newData = [...localData, newCutoff];
    setLocalData(newData);
    handleInputChange('data', newData);
  };

  const handleDeleteCutoff = (index: number) => () => {
    const newData = localData.filter((_, i) => i !== index);
    setLocalData(newData);
    handleInputChange('data', newData);
  };

  const handleBlur = (index: number, field: 'cutoff' | 'label') => (e: CustomEvent) => {
    const value = (e.target as HTMLInputElement).value;
    const newData = localData.map((cutoff, i) =>
      i === index ? { ...cutoff, [field]: field === 'cutoff' ? parseFloat(value) : value } : cutoff
    );
    setLocalData(newData);
    handleInputChange(`data.${index}.${field}`, value);
  };

  const handleLocalChange = (index: number, field: 'cutoff' | 'label') => (e: CustomEvent) => {
    const value = (e.target as HTMLInputElement).value;
    const newData = localData.map((cutoff, i) =>
      i === index ? { ...cutoff, [field]: field === 'cutoff' ? parseFloat(value) : value } : cutoff
    );
    setLocalData(newData);
  };

  return (
    <IonList>
      {localData.length === 0 ? (
        <IonItem>
          <IonLabel>No cutoffs set</IonLabel>
        </IonItem>
      ) : (
        localData.map((cutoff, index) => (
          <IonItem key={index}>
            <IonLabel>Cutoff</IonLabel>
            <IonInput
              type="number"
              value={cutoff.cutoff || 0}
              aria-label={`Cutoff ${index}`}
              onIonChange={handleLocalChange(index, 'cutoff')}
              onIonBlur={handleBlur(index, 'cutoff')}
            />
            <IonLabel>Label</IonLabel>
            <IonInput
              value={cutoff.label}
              aria-label={`Label ${index}`}
              onIonChange={handleLocalChange(index, 'label')}
              onIonBlur={handleBlur(index, 'label')}
            />
            <IonButton slot="end" color="danger" onClick={handleDeleteCutoff(index)}>
              <IonIcon slot="icon-only" icon={trash} />
            </IonButton>
          </IonItem>
        ))
      )}
      <IonButton onClick={handleAddCutoff}>
        <IonIcon slot="start" icon={add} />
        Add Cutoff
      </IonButton>
    </IonList>
  );
};

const RecommendationsTableEditor: React.FC<{
  data: RecommendationsTableType['data'];
  handleInputChange: (path: string, value: any) => void;
}> = ({ data, handleInputChange }) => (
  <IonList>
    {data.map((rec, index) => (
      <IonItem key={index}>
        <IonLabel>Keys</IonLabel>
        {Object.entries(rec.keys).map(([key, value]) => (
          <IonItem key={key}>
            <IonLabel>{key}</IonLabel>
            <IonInput
              value={value}
              aria-label={`Key ${key}`}
              onIonChange={(e) => handleInputChange(`data.${index}.keys.${key}`, e.detail.value)}
            />
          </IonItem>
        ))}
        <IonLabel>Light Color</IonLabel>
        <IonSelect
          value={rec.lightColor}
          aria-label={`Light Color ${index}`}
          onIonChange={(e) => handleInputChange(`data.${index}.lightColor`, e.detail.value)}
        >
          <IonSelectOption value="green">Green</IonSelectOption>
          <IonSelectOption value="yellow">Yellow</IonSelectOption>
          <IonSelectOption value="red">Red</IonSelectOption>
        </IonSelect>
        <IonLabel>Tags</IonLabel>
        <IonTextarea
          value={rec.tags.join(', ')}
          aria-label={`Tags ${index}`}
          onIonChange={(e) => handleInputChange(`data.${index}.tags`, e.detail.value?.split(', '))}
        />
        <IonLabel>Description</IonLabel>
        <IonTextarea
          value={rec.description}
          aria-label={`Description ${index}`}
          onIonChange={(e) => handleInputChange(`data.${index}.description`, e.detail.value)}
        />
      </IonItem>
    ))}
  </IonList>
);

export default AdminParameterEditor;
