import React, { useState, useEffect } from 'react';
import { IonCol, IonRow, IonGrid, IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonItem, IonLabel, IonSearchbar, IonButton } from '@ionic/react';
import MultiColumnContent from './multi-column-content';
import JuniorAdvisorsManager from '../../services/client-records/junior-advisors-manager';
import { useClients } from '../../services/client-records/clients-provider';
import ClientCreator from '../../services/client-records/client-creator';
import { ReportSummaryCard } from './report-summary-card';

const PracticeDashboard: React.FC = () => {
  const tabs = [
    { title: 'Clients', content: <Tab1Content /> },
    { title: 'Recent Reports', content: <Tab2Content /> },
    { title: 'Manage Junior Advisors', content: <JuniorAdvisorsManager/>},
  ];

  return <>
    <MultiColumnContent tabs={tabs} />
  </>;
};

// const fakeClientData = [
//   {
//     name: "Adam Smith",
//     lastMeeting: new Date(new Date().setDate(new Date().getDate() - 7)), // 7 days ago
//     lastReportRun: new Date(new Date().setDate(new Date().getDate() - 14)), // 14 days ago
//   },
//   {
//     name: "John Maynard Keynes",
//     lastMeeting: new Date(new Date().setDate(new Date().getDate() - 15)), // 15 days ago
//     lastReportRun: new Date(new Date().setDate(new Date().getDate() - 30)), // 30 days ago
//   },
//   {
//     name: "Milton Friedman",
//     lastMeeting: new Date(new Date().setDate(new Date().getDate() - 20)), // 20 days ago
//     lastReportRun: new Date(new Date().setDate(new Date().getDate() - 25)), // 25 days ago
//   },
//   {
//     name: "David Ricardo",
//     lastMeeting: new Date(new Date().setDate(new Date().getDate() - 10)), // 10 days ago
//     lastReportRun: new Date(new Date().setDate(new Date().getDate() - 20)), // 20 days ago
//   },
//   {
//     name: "Karl Marx",
//     lastMeeting: new Date(new Date().setDate(new Date().getDate() - 5)), // 5 days ago
//     lastReportRun: new Date(new Date().setDate(new Date().getDate() - 10)), // 10 days ago
//   },
// ];
const formatDate = (date: Date): string => {
  return date.toLocaleDateString();
};
const Tab1Content: React.FC = () => {
  const [searchText, setSearchText] = useState('');
  const { myClients } = useClients();

  const filteredClients = myClients.filter(client =>
    client.fullName.toLowerCase().includes(searchText.toLowerCase())
  );

  return (<>
    <IonSearchbar
      value={searchText}
      onIonChange={e => setSearchText(e.detail.value!)}
      placeholder="Search Clients"
    />
    <ClientCreator/>
    {filteredClients.map((client, index) => (
      <IonCard key={index} style={{marginBottom: "1em"}} routerLink={`/clients/${client.id}`}>
        <IonCardHeader>
          <IonCardTitle>{client.fullName}</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <IonItem>
            <IonLabel>
              <h2>Last Meeting</h2>
              {/* <p>{formatDate(client.lastMeeting)}</p> */}
              <p>N/A</p>
            </IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel>
              <h2>Last Report Run</h2>
              {/* <p>{formatDate(client.lastReportRun)}</p> */}
              <p>N/A</p>
            </IonLabel>
          </IonItem>
        </IonCardContent>
      </IonCard>
    ))}
    {filteredClients.length <= 0 && <p>Expected to see clients here? Try refreshing the page and (if necessary) create the records again.</p>}
  </>);
};

// const dummyDocuments = [
//   {
//     clientName: "Adam Smith",
//     reportDate: new Date(new Date().setDate(new Date().getDate() - 1)), // 1 day ago
//     reportType: 'Albridge',
//     status: 'processing',
//   },
//   {
//     clientName: "John Maynard Keynes",
//     reportDate: new Date(new Date().setDate(new Date().getDate() - 2)), // 2 days ago
//     reportType: 'Morningstar',
//     status: 'analyzing',
//   },
//   {
//     clientName: "Milton Friedman",
//     reportDate: new Date(new Date().setDate(new Date().getDate() - 3)), // 3 days ago
//     reportType: 'Albridge',
//     status: 'recommendations available',
//   },
//   {
//     clientName: "David Ricardo",
//     reportDate: new Date(new Date().setDate(new Date().getDate() - 4)), // 4 days ago
//     reportType: 'Morningstar',
//     status: 'processing',
//   },
//   {
//     clientName: "Karl Marx",
//     reportDate: new Date(new Date().setDate(new Date().getDate() - 5)), // 5 days ago
//     reportType: 'Albridge',
//     status: 'analyzing',
//   },
// ];
const Tab2Content: React.FC = () => {
  const { myReports } = useClients();
  const allReports = Array.from(myReports.values()).flat();

  // TODO: Check sort order.
  const sortedDocuments = [...allReports].sort((a, b) => (b.createdAt?.getTime() || 0) - (a.createdAt?.getTime() || 0));

  // TODO: Improve report summary view; extract into a card-based component.
  return (
    <>
      {sortedDocuments.map((document, index) => <ReportSummaryCard report={document} key={index}/>)}
    </>
  );
};

export default PracticeDashboard;
