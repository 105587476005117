import React, { useState } from 'react';
import { IonButton, IonContent, IonIcon, IonInput, IonItem, IonLabel, IonList, IonListHeader, IonText } from '@ionic/react';
import { addCircleOutline, removeCircleOutline } from 'ionicons/icons';
import { useClients } from './clients-provider';

const JuniorAdvisorsManager: React.FC = () => {
  const { myJuniorAdvisors, addJuniorAdvisor, removeJuniorAdvisor, mySeniorAdvisors } = useClients();
  const [newAdvisorEmail, setNewAdvisorEmail] = useState<string>('');
  const [error, setError] = useState<string | null>(null);

  const handleAddAdvisor = async () => {
    if (!newAdvisorEmail) {
      setError('Email cannot be empty');
      return;
    }
    try {
      await addJuniorAdvisor(newAdvisorEmail);
      setNewAdvisorEmail('');
      setError(null);
    } catch (err) {
      setError('Failed to add Junior Advisor');
      console.error(err);
    }
  };

  const handleRemoveAdvisor = async (id: string) => {
    try {
      await removeJuniorAdvisor(id);
      setError(null);
    } catch (err) {
      setError('Failed to remove Junior Advisor');
      console.error(err);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleAddAdvisor();
    }
  };

  return (
    <>
      <IonList>
        <IonItem>
          <IonInput
            type="email"
            placeholder="Enter junior advisor's email"
            value={newAdvisorEmail}
            onIonChange={(e) => setNewAdvisorEmail(e.detail.value!)}
            onKeyDown={handleKeyDown}
          />
          <IonButton onClick={handleAddAdvisor}>
            <IonIcon slot="icon-only" icon={addCircleOutline} />
          </IonButton>
        </IonItem>
        {error && <IonText color="danger">{error}</IonText>}
        {myJuniorAdvisors.map((advisor) => (
          <IonItem key={advisor.id}>
            <IonLabel>{advisor.juniorAdvisorEmail}</IonLabel>
            <IonButton color="danger" onClick={() => handleRemoveAdvisor(advisor.id)}>
              <IonIcon slot="icon-only" icon={removeCircleOutline} />
            </IonButton>
          </IonItem>
        ))}
      </IonList>
      {mySeniorAdvisors.length > 0 && <>
        <hr/>
        <IonListHeader><IonLabel>My Senior Advisors:</IonLabel></IonListHeader>
        <IonList>
          {mySeniorAdvisors.map((advisorRecord) => (
            <IonItem key={advisorRecord.id}>
              <IonLabel>{advisorRecord.seniorAdvisorEmail}</IonLabel>
            </IonItem>
          ))}
        </IonList>
      </>}
    </>
  );
};

export default JuniorAdvisorsManager;
