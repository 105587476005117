import React from "react";
import { useAuth } from "../services/AuthProvider";
import { useData } from "../services/DataProvider";

export const AdminToggle:React.FC<{children:React.ReactNode, nonAdminComponent?:React.ReactNode}> = ({children, nonAdminComponent, ...props})=>{
  const { permissions } = useData();

  return <>
    {permissions.isAdmin? children : nonAdminComponent}
  </>
}
