import { useEffect, useState } from "react";
import { useQueue } from "../fullstack-utilities/task-queue-provider"

// Simple sleep function that returns a promise that resolves after a given time
const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export const Mockup: React.FC = () => {
  const {addToQueue} = useQueue();

  const [taskCount, setTaskCount] = useState(1);
  const [taskCount2, setTaskCount2] = useState(1);

  const [finishedLog, setFinishedLog] = useState([] as number[]);
  const [finishedLog2, setFinishedLog2] = useState([] as number[]);

  const addTaskToQueue = (queueIndex: 1 | 2) => {
    let taskId: number;
    if (queueIndex === 1) {
      taskId = taskCount;
      setTaskCount(taskCount + 1);
    } else {
      taskId = taskCount2;
      setTaskCount2(taskCount2 + 1);
    }

    addToQueue(async () => {
      console.log(`Task ${taskId} started`);
      await sleep(1000 * taskId); // Each task waits for time proportional to its ID
      console.log(`Task ${taskId} completed`);
      if (queueIndex === 1) {
        setFinishedLog((prev)=>[...prev, taskId]);
      } else {
        setFinishedLog2((prev)=>[...prev, taskId]);
      }
    }, queueIndex.toString());
  };

  return (
    <div>
      <p>Completed Tasks: {finishedLog.join(", ")}</p>
      <button onClick={()=>addTaskToQueue(1)}>Add Task to Queue 1</button>
      <br/>
      <br/>
      <p>Completed Tasks 2: {finishedLog2.join(", ")}</p>
      <button onClick={()=>addTaskToQueue(2)}>Add Task to Queue 2</button>
    </div>
  );
}