import { IonCol, IonContent, IonGrid, IonPage, IonRow, IonText } from "@ionic/react"
import { Link } from "react-router-dom";
import { AdminImageSelector } from "../components/AdminImageSelector";
import { SocialButtons } from "../components/SocialButtons";

export const Splash: React.FC = () => {
  const contrastColor = "#FFFFFF";

  const columnStyle = {
    color: contrastColor,
    padding: "1em",
  };

  return <IonPage>
    <IonContent style={{"--background":"url('/assets/fallback_splash.jpg') no-repeat center center / cover", backgroundColor:"#000000"}}>
      <IonGrid style={{position:"absolute", top:"0", height:"100%", width:"100%", display: "flex", flexDirection: "column"}}>
        <IonRow>
          <IonCol size="4">
            <p style={{textAlign:"left", color: contrastColor}}><Link to="about">About</Link> | <Link to="pricing">Pricing</Link> | <a href="mailto:acumplido@indfp.com">Contact</a></p>
          </IonCol>
          <IonCol size="4">
            <h1 style={{textAlign:"center", color: contrastColor}}>My Junior Advisor</h1>
          </IonCol>
          <IonCol size="4">
            <p style={{textAlign:"right", color: contrastColor}}><SocialButtons/></p>
          </IonCol>
        </IonRow>
        <IonRow style={{height:"100%", display: "flex", alignItems: "center", textAlign:"center"}}>
          <IonCol size="12" sizeSm="6" style={columnStyle}>
            <div style={{textShadow:"0 0 3px #000000", textAlign:"left", display:"inline-block"}}>
              <p>White Labeled Investment & Financial Planning Content <br/>Exclusively for AMP Consulting Group Clients</p>
              <h1><span style={{fontSize:"2em"}}>Content Studio</span></h1>
              <br/>
              <p style={{fontSize:"1.5em"}}><Link to="/home">Login →</Link></p>
            </div>
          </IonCol>
          <IonCol size="12" sizeSm="6" style={columnStyle}>
            <h3>"Confirmation Bias"</h3>
            {/* <AdminImageSelector fallbackImageURL="/assets/fallback_art.jpg" fileName="splash_art" style={{aspectRatio:1, objectFit:"cover", objectPosition:"center"}}/> */}
            <img alt="Confirmation Bias - An abstract painting of a safe inside another box."  src="/assets/fallback_art.jpg"/>
            <p>The safe inside the larger box represents the feeling of safety that comes from only thinking inside the box and simply looking for information that confirms our current beliefs. <i>Art inspired by the style of Jean-Michel Basquiat.</i></p>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonContent>
  </IonPage>
}