export type QuestionType =
  | { type: 'text'; question: string; value: string }
  | { type: 'number'; question: string; value: number }
  | { type: 'multipleChoice'; question: string; options: string[]; value: string[], multiple?:boolean } //multiple indicates if multiple selections are allowed.
  | { type: 'slider'; question: string; min: number; max: number; value: number };

export const surveyQuestions = {
  fullName: { type: 'text', question: 'Name', value: '' },
  dob: { type: 'text', question: 'Date of Birth', value: '' },
  annualIncome: { type: 'number', question: 'Annual Income', value: 0 },
  accountTypes: { type: 'multipleChoice', question: 'Account Types Being Evaluated', options: ['Retirement', 'Non-Retirement'], value: [] as string[], multiple: true },
  retirementAssets: { type: 'number', question: 'Retirement Assets', value: 0 },
  nonRetirementAssets: { type: 'number', question: 'Non-Retirement Assets', value: 0 },
  totalAssets: { type: 'number', question: 'Total Assets', value: 0 },
  totalInvestments: { type: 'number', question: 'Total Investments', value: 0 },
  totalCash: { type: 'number', question: 'Total Cash and Cash Equivalents', value: 0 },
  totalLiabilities: { type: 'number', question: 'Total Liabilities', value: 0 },
  monthlyExpenses: { type: 'number', question: 'Total Monthly Expenses', value: 0 },
  liquidNetWorth: { type: 'number', question: 'Liquid Net Worth', value: 0 },
  investmentObjectives: { type: 'multipleChoice', question: 'Investment Objectives', options: ['Capital Appreciation', 'Growth', 'Speculation', 'Capital Preservation', 'Income', 'Tax-Advantage'], value: [] as string[], multiple: true },
  timeHorizon: { type: 'multipleChoice', question: 'Time Horizon', options: ['Under 1 year', '1-2 years', '3-5 years', '6-10 years', '11-20 years', 'Over 20 years'], value: '' },
  liquidityNeeds: { type: 'text', question: 'Liquidity Needs', value: '' },
  financialExperience: { type: 'slider', question: 'Financial Experience', min: 1, max: 10, value: 1 },
  riskTolerance: { type: 'multipleChoice', question: 'Risk Tolerance', options: ['Conservative', 'Moderately Conservative', 'Moderate', 'Moderately Aggressive', 'Aggressive'], value: '' },
  taxBracket: { type: 'multipleChoice', question: 'Tax Bracket', options: ['10%', '12%', '22%', '24%', '32%', '35%', '37%'], value: '' },
  shortTermGoal: { type: 'text', question: 'Short Term Financial Goal', value: '' },
  longTermGoals: { type: 'text', question: 'Top 3 Long Term Financial Goals', value: '' },
};

export type SurveyKeys = keyof typeof surveyQuestions;

export type SurveyAnswers = {
  [K in keyof typeof surveyQuestions]: typeof surveyQuestions[K]['value'];
};
