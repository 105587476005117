import React, { useState, useEffect, useRef } from 'react';
import { IonItem, IonLabel, IonText, IonSpinner } from '@ionic/react';
import { getStorage, ref, uploadBytesResumable } from 'firebase/storage';
import { getAuth } from 'firebase/auth';

interface FileUploadProps {
  clientId: string;
  documentType: 'holdings report' | 'analysis';
  reportId: string;
  onUploadSuccess?: () => void;
}

const FileUpload: React.FC<FileUploadProps> = ({ clientId, documentType, reportId, onUploadSuccess }) => {
  const [file, setFile] = useState<File | null>(null);
  const [uploading, setUploading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const storage = getStorage();
  const auth = getAuth();
  const currentUser = auth.currentUser;

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];
    if (selectedFile && selectedFile.type === 'application/pdf') {
      setFile(selectedFile);
      setError(null);
    } else {
      setError('Please select a valid PDF file.');
    }
  };

  const handleUpload = async () => {
    if (!file) {
      setError('Please select a file.');
      return;
    }

    if (!currentUser) {
      setError('You must be logged in to upload files.');
      return;
    }

    setUploading(true);
    setError(null);

    try {
      // Prepend current system time to the file name
      const timestamp = Date.now();
      const fileName = `${timestamp}_${file.name}`;
      const storageRef = ref(storage, `clients/${clientId}/documents/${fileName}`);
      // console.log(JSON.stringify({
      //   uploaderId: currentUser.uid,
      //   uploaderEmail: currentUser.email!,
      //   documentType: documentType,
      //   clientId: clientId,
      // }));
      const uploadTask = uploadBytesResumable(storageRef, file, {
        customMetadata: {
          uploaderId: currentUser.uid,
          uploaderEmail: currentUser.email!,
          documentType: documentType,
          reportId: reportId,
          clientId: clientId,
        },
      });

      uploadTask.on('state_changed',
        (snapshot) => {
          // Handle progress, if necessary
        },
        (error) => {
          setError('Failed to upload file.');
          setUploading(false);
        },
        async () => {
          setUploading(false);
          setSuccessMessage(`File '${file.name}' uploaded successfully!`);
          setFile(null); // Nullify the file input after successful upload

          if (fileInputRef.current) {
            fileInputRef.current.value = ''; // Reset the file input
          }

          if (onUploadSuccess) {
            onUploadSuccess();
          }
        }
      );
    } catch (error) {
      setError('Failed to upload file.');
      setUploading(false);
    }
  };

  useEffect(() => {
    if (file) {
      handleUpload();
    }
  }, [file]);

  return (
    <>
      <IonItem>
        <IonLabel>Upload PDF</IonLabel>
        <input 
          type="file" 
          accept="application/pdf" 
          onChange={handleFileChange} 
          disabled={uploading} 
          style={{ display: 'block', marginTop: '10px' }}
          ref={fileInputRef}
        />
      </IonItem>

      {error && <IonText color="danger">{error}</IonText>}
      {successMessage && <IonText color="success">{successMessage}</IonText>}

      {uploading && (
        <IonItem>
          <IonSpinner />
          <IonLabel>Uploading...</IonLabel>
        </IonItem>
      )}
    </>
  );
};

export default FileUpload;
