// A file with helper functions to extract holdings data and Morningstar data from returned data objects.

import { proxyChatCall } from "../../App";

export const generateFormattedText = async (parseString: string) => {
  const functionCallName = "storeFormattedText";
  const properties: { [key: string]: any } = {};
  properties['verbatim_text'] = {
    type: 'string',
    description: `Pre-formatted / verbatim text extracted from the provided parse object.`,
  };
  const response = await proxyChatCall({
    model: "gpt-4o", // Results are better enough that this is worth it.
    // model: "gpt-4-turbo",
    temperature: 0,
    seed: 0,
    messages: [
      {
        role: "system",
        content: `You are a note-taking assistant for a financial advisor who is reviewing a client investment holdings report, provided by the user as a parse object extracted from a scanned PDF. Please output a structured text document (verbatim string / pre-formatted text) that recreates the content of each page of the PDF from the parse. Return the extracted text for all pages as a single string/text-document.`,
      },
      {
        role: "user",
        content: `${parseString}`,
      },
    ],
    tools: [
      {
        type: 'function',
        function: {
          name: functionCallName,
          description:
            "Store the result of formatting the parsed text as a verbatim string.",
          parameters: {
            type: 'object',
            properties: properties,
            required: Object.keys(properties), // All properties are required.
          },
        },
      },
    ],
    tool_choice: { type: 'function', function: { name: functionCallName } },
  });
  const result = JSON.parse(
    (response.data as any)?.choices?.[0]?.message?.tool_calls?.[0]?.function.arguments
  )?.["verbatim_text"];

  return result;
}


export interface Account {
  accountName:            string;
  taxAdvantagedStatus:    TaxAdvantagedStatus;
  holdings:               Holding[];
  totalValue:             number;
}
export interface Holding {
  symbol?:    string | null;
  longName?:  string | null;
  value:      number;
}
export enum TaxAdvantagedStatus {
  No = "no",
  Unknown = "unknown",
  Yes = "yes",
}
export const extractTables = async (formattedString: string) => {
  const functionCallName = "log_holdings_tables";
  const properties: { [key: string]: any } = {};
  properties['accounts'] = {
    "type": "array",
    "items": {
      "type": "object",
      "properties": {
        "accountName": {
          "type": "string"
        },
        "taxAdvantagedStatus": {
          "type": "string",
          "enum": ["yes", "no", "unknown"]
        },
        "holdings": {
          "type": "array",
          "items": {
            "type": "object",
            "properties": {
              "symbol": {
                "type": ["string", "null"]
              },
              "longName": {
                "type": ["string", "null"]
              },
              "value": {
                "type": "number"
              }
            },
            "required": ["value"]
          }
        },
        "totalValue": {
          "type": "number"
        }
      },
      "required": ["accountName", "taxAdvantagedStatus", "holdings", "totalValue"],
    },
    description: `A table of holdings and their values for each of the investor's accounts.`,
  };
  const response = await proxyChatCall({
    model: "gpt-4o", // Results are better enough that this is worth it.
    // model: "gpt-4-turbo",
    temperature: 0,
    seed: 0,
    messages: [
      {
        role: "system",
        content: `You are a note-taking assistant for a financial advisor examining a client investment holdings report, transcribed in the user's message. Please extract the name of each account, and indicate whether it has a tax-advantaged / qualified status based on the name (e.g. IRA's and 401K's/403B's are qualified accounts). Then give a table of the holdings in that account (symbol, name if given, and value). Finally, report the total value of the holdings in that account.`,
      },
      {
        role: "user",
        content: `${formattedString}`,
      },
    ],
    tools: [
      {
        type: 'function',
        function: {
          name: functionCallName,
          description:
            "Store the result of extracting the holdings in tabular form.",
          parameters: {
            type: 'object',
            properties: properties,
            required: Object.keys(properties), // All properties are required.
          },
        },
      },
    ],
    tool_choice: { type: 'function', function: { name: functionCallName } },
  });
  const result = JSON.parse(
    (response.data as any)?.choices?.[0]?.message?.tool_calls?.[0]?.function.arguments
  )?.["accounts"] as Account[];

  return result;
}

export interface ExtractedMorningstarData {
  assetAllocation: {
    cash: number,
    usStocks: number,
    nonUSStocks: number,
    bonds: number,
    other: number,
  },
  fixedIncomeSectors: {
    sector: string,
    portfolioPercentage: number,
  }[],
  creditQualityBreakdown: {
    creditRating: string,
    portfolioPercentage: number,
  }[],
  stockSectors: {
    category: "Cyclical" | "Sensitive" | "Defensive",
    sector: string,
    portfolioPercentage: number,
  }[],
  effectiveBondDuration: number,
  northAmericanEquityPercentage: number,
  equityFlavor: {
    largeGrowth: number,
    largeBlend: number,
    largeValue: number,
    midGrowth: number,
    midBlend: number,
    midValue: number,
    smallGrowth: number,
    smallBlend: number,
    smallValue: number,
  },
  // TODO:
}

// Helper function to call openai API for requested properties.
const callChatWithProperties = async (parseString: string, properties: { [key: string]: any } ) => {
  const functionCallName = "save_report_data";
  const response = await proxyChatCall({
    model: "gpt-4o", // Results are better enough that this is worth it.
    // model: "gpt-4-turbo",
    temperature: 0,
    seed: 0,
    max_tokens: 4096,// Seems to throw an error if this gets too big. Maybe because of old API version.
    messages: [
      {
        role: "system",
        content: `You are a note-taking assistant for a financial advisor examining a parsed document analyzing an investment portfolio. The user's message is a parse document for a PDF that gives a summary of various aspects of a financial portfolio. Your job will be to identify JSON objects from the parse that are potentially relevant to each of several questions, and then to extract the requested piece of information from the JSON objects you identified.`,
      },
      {
        role: "user",
        content: parseString,
      },
    ],
    tools: [
      {
        type: 'function',
        function: {
          name: functionCallName,
          description:
            "Store the result of extracting each of several key pieces of information from the parsed report.",
          parameters: {
            type: 'object',
            properties: properties,
            required: Object.keys(properties), // All properties are required.
          },
        },
      },
    ],
    tool_choice: { type: 'function', function: { name: functionCallName } },
  });
  const endCondition = (response.data as any)?.choices?.[0]?.finish_reason;
  console.log(endCondition);
  console.log(`Num tokens in output: ${(response.data as any)?.usage?.completion_tokens} / ${(response.data as any)?.usage?.total_tokens} total`);
  let result = null; // TODO: Handle more gracefully. UI should also allow for retries.
  if (endCondition !== "length") {
    result = JSON.parse(
      (response.data as any)?.choices?.[0]?.message?.tool_calls?.[0]?.function.arguments
    );//?.["relevant_statements"];
  } else {
    console.error("Max Length exceeded!");
    // TODO: In a properly-robust system, we would try generation of the next 4096 tokens (current output limit, as far as I can tell), conditioned on the previous 4096 tokens and the original prompt, up to the full 128K context limit.
    // But, things being as they are, I'm currently just subdividing the tasks and setting limits on items per list (which seems to help); there's no major difference in the number of tokens/cost, since the number of calls is roughly the same; this is just slightly less robust (each individual call has to produce less than 4096 output tokens, e.g.)
  }

  return result as Partial<ExtractedMorningstarData>;
};

export const extractMorningstarData = async (parseString: string) => {
  const properties: { [key: string]: any } = {};
  // properties['asset_allocation_relevant_items'] = {
  //   type: 'array',
  //   maxItems: 10, // 5 values + 2-ish labels and 3 for margin of error.
  //   items: {
  //     type: 'string',
  //   },
  //   description: `A list of the JSON str/transform objects potentially relevant to extracting the high-level asset allocation percentages.`,
  // };
  properties['assetAllocation'] = {
    "type": "object",
    "properties": {
      "cash": {
        "type": "number"
      },
      "usStocks": {
        "type": "number"
      },
      "nonUSStocks": {
        "type": "number"
      },
      "bonds": {
        "type": "number"
      },
      "other": {
        "type": "number"
      }
    },
    "required": ["cash", "usStocks", "nonUSStocks", "bonds", "other"],
    description: `The percentage values given in the parsed report for each major asset allocation category.`,
  };

  properties["fixedIncomeSectors"] = {
    type: 'array',
    items: {
      type: 'object',
      properties: {
        "sector": {type: "string"},
        "portfolioPercentage": {type: "number"},
      }
    },
    description: "The list under 'Bond Analysis' and 'Fixed-Income Sectors' of bond sectors and their respective Portfolio (%).",
  };

  properties["creditQualityBreakdown"] = {
    type: 'array',
    items: {
      type: 'object',
      properties: {
        "creditRating": {type: "string"},
        "portfolioPercentage": {type: "number"},
      }
    },
    description: "The list under 'Credit Quality Breakdown' of credit qualities and their respective Portfolio (%).",
  };

  properties["stockSectors"] = {
    type: 'array',
    items: {
      type: 'object',
      properties: {
        "category": {
          "type": "string",
          "enum": ["Cyclical", "Sensitive", "Defensive"]
        },
        "sector": {type: "string"},
        "portfolioPercentage": {type: "number"},
      }
    },
    description: "The list under 'Stock Sectors' from categories 'Cyclical', 'Sensitive', and 'Defensive' of stock sectors and their respective Portfolio (%). Note that this list disregards the aggregate percentages for Cyclical, Sensitive, Defensive, and Not Classified super-sectors, and only lists the sub-sectors presented in the table.",
  };

  properties["northAmericanEquityPercentage"] = {
    type: "number",
    description: "The Portfolio (%) reported under 'World Regions' under 'Americas'.",
  }

  properties["effectiveBondDuration"] = {
    type: "number",
    description: "The number reported under 'Fixed-Income Style' with the label 'Effective Duration'."
  }

  // properties['equity_flavor_relevant_items'] = {
  //   type: 'array',
  //   maxItems: 20, // 9 values + 6 labels (+ 5 for margin of error)
  //   items: {
  //     type: 'string',
  //   },
  //   description: `A list of the JSON str/transform objects potentially relevant to extracting the equity flavor allocation percentages. Note that the numbers are in a 3x3 table with one axis showing small, mid, and large-cap stocks, and the other showing the company stability as growth, blend, or value. Please report numbers for all 9 combinations.`,
  // };
  properties['equityFlavor'] = {
    "type": "object",
    "properties": {
      "largeValue": {type: "number"},
      "largeBlend": {type: "number"},
      "largeGrowth": {type: "number"},
      "midValue": {type: "number"},
      "midBlend": {type: "number"},
      "midGrowth": {type: "number"},
      "smallValue": {type: "number"},
      "smallBlend": {type: "number"},
      "smallGrowth": {type: "number"},
    },
    "required": ["largeValue", "largeBlend", "largeGrowth", "midValue", "midBlend", "midGrowth", "smallValue", "smallBlend", "smallGrowth"],
    description: `The percentage values given in the parsed report for each equity flavor category.`,
  };



  const returnData: Partial<ExtractedMorningstarData> = {};
  Object.assign(returnData, await callChatWithProperties(parseString, properties));
  // It turns out that the below may be unnecessary:

  // const entries = Object.entries(properties);
  // for (let i = 0; i < entries.length; i += 2) { // Properties defined in pairs (in an order) of "helpful objects" and "extracted values".
  //   const propertyPair = {
  //     [entries[i][0]]: entries[i][1],
  //     [entries[i + 1][0]]: entries[i + 1][1]
  //   };
  //   const newResults = await callChatWithProperties(parseString, propertyPair);
  //   Object.assign(returnData, newResults);
  // }
  return returnData as ExtractedMorningstarData;
}