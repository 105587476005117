import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCheckbox, IonCol, IonContent, IonGrid, IonInput, IonItem, IonLabel, IonPage, IonRow, IonText} from "@ionic/react"
import React, { FormEvent, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Breadcrumbs } from "../components/Breadcrumbs";
import { SocialButtons } from "../components/SocialButtons";
import { useAuth } from "../services/AuthProvider";

export const LoginGuard: React.FC<{children: React.ReactNode}> = ({children, ...props}) => {
  const {currentUser, login, signup, resetPassword} = useAuth();

  const [passwordResetMode, setPasswordResetMode] = useState(false);
  const [loginMode, setLoginMode] = useState(true); // Login vs. Signup.

  const emailRef = useRef<HTMLIonInputElement>(null);
  const passwordRef = useRef<HTMLIonInputElement>(null);
  const confirmPasswordRef = useRef<HTMLIonInputElement>(null);
  const readAgreementsRef = useRef<HTMLIonCheckboxElement>(null);

  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  async function handleSubmit(event:FormEvent) {
    event.preventDefault(); // Prevents page from refreshing on form submit.
    setError('');
    setMessage("");

    if (passwordResetMode) {
      setLoading(true); //Because the function is async, maybe it is possible for rerender to happen while loading.
      await (resetPassword("" + emailRef.current?.value)?.then(()=>{
        setMessage("Check your email inbox for further instructions.")
      }).catch((reason)=>{
        setError('Failed to reset password. ' + reason.message || "");
        setLoading(false);
      }));
    } else if (loginMode) {
      setLoading(true); //Because the function is async, maybe it is possible for rerender to happen while loading.
      await (login("" + emailRef.current?.value, "" + passwordRef.current?.value)?.then(()=>{
        // Nothing to do if successful. Just display children.
      }).catch((reason)=>{
        setError('Failed to log in: ' + (reason.message || ""));
        setLoading(false);
      }));
    } else { // signup mode
      if(!readAgreementsRef.current?.checked) {
        setError("You must agree to the Privacy Policy and Terms and Conditions.");
        return;
      }

      // Password Length checked by Firebase's auth requirements.
      if (passwordRef.current?.value !== confirmPasswordRef.current?.value) {
        setError("Passwords do not match.");
        return;
      }

      setLoading(true); //Because the function is async, maybe it is possible for rerender to happen while loading.
      await (signup("" + emailRef.current?.value, "" + passwordRef.current?.value)?.then(()=>{
        setLoading(false);
        // redirect/re-render happens at the component and/or router level.
      }).catch((reason)=>{
        setError('Failed to create an account: ' + (reason.message || ""));
        setLoading(false);
      }));
    }
  }

  const contrastColor = "#FFFFFF";

  const columnStyle = {
    color: contrastColor,
    padding: "1em",
  };

  return <>{currentUser?
  children
  :
  <IonPage>
    <IonContent style={{"--background":"url('/assets/fallback_splash.jpg') no-repeat center center / cover"}}>
      <IonGrid style={{position:"absolute", top:"0", height:"100%", width:"100%", display: "flex", flexDirection: "column"}}>
        <IonRow>
          <IonCol size="4">
            <p style={{textAlign:"left", color: contrastColor}}><Breadcrumbs /></p>
          </IonCol>
          <IonCol size="4">
            <h1 style={{textAlign:"center", color: contrastColor}}>My Junior Advisor</h1>
          </IonCol>
          <IonCol size="4">
            <p style={{textAlign:"right", color: contrastColor}}><SocialButtons/></p>
          </IonCol>
        </IonRow>
        <IonRow style={{height:"100%", display: "flex", alignItems: "center", textAlign:"center"}}>
          <IonCol size="12" sizeSm="12" style={columnStyle}>
            <IonCard style={{maxWidth:"60em", margin:"auto"}}>
              <IonCardHeader>
                <IonCardTitle>{passwordResetMode? "Password Reset" : (loginMode? "Login" : "Signup")}</IonCardTitle>
              </IonCardHeader>
              
              <IonCardContent>
                {!!message? <IonText color={'success'}><h3>{message}</h3></IonText>: <></>}
                {!!error? <IonText color={'danger'}><h3>{error}</h3></IonText>: <></>}
                <form onSubmit={handleSubmit} onKeyUp={(event)=>{if(event.key === "Enter") {handleSubmit(event);}}}>
                <IonItem>
                  <IonLabel position="floating">Email</IonLabel>
                  <IonInput type="email" ref={emailRef} placeholder="example: first.last@gmail.com"/>
                </IonItem>
                {!passwordResetMode?<>
                  <IonItem>
                    <IonLabel position="floating">Password</IonLabel>
                    <IonInput type="password" ref={passwordRef}/>
                  </IonItem>
                  {!loginMode?<>
                    <IonItem>
                      <IonLabel position="floating">Confirm Password</IonLabel>
                      <IonInput type="password" ref={confirmPasswordRef}/>
                    </IonItem>
                    <IonItem>
                      <IonCheckbox slot="start" ref={readAgreementsRef}/>
                      <IonLabel text-wrap><IonText color="medium">I have read and agree to the <Link to="privacy">Privacy Policy</Link> and <Link to="terms">Terms and Conditions</Link>.</IonText></IonLabel>
                    </IonItem>
                  </>
                  :<></>}
                </>
                :<></>}
                <IonButton expand="block" type="submit" className="ion-margin-top" disabled={loading}>
                  {passwordResetMode? "Send Password Reset Email" : (loginMode? "Log In" : "Sign Up")}
                </IonButton>
                <p style={{margin:"auto"}}><span style={{textDecoration:"underline", cursor:"pointer"}} onClick={()=>{setPasswordResetMode(!passwordResetMode);}}>{passwordResetMode? "Log In / Sign Up?": "Forgot Password?"}</span></p>
                </form>
                {loginMode?
                <p style={{margin:"auto"}}>Need an account? <IonButton type="button" color="secondary" onClick={()=>{setLoginMode(!loginMode)}}>Sign Up</IonButton></p>
                :
                <p style={{margin:"auto"}}>Have an account? <IonButton type="button" color="secondary" onClick={()=>{setLoginMode(!loginMode)}}>Log In</IonButton></p>
                }
              </IonCardContent>
            </IonCard>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonContent>
  </IonPage>}
  </>
}