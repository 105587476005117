import { SurveyAnswers } from "../../components/kyc-editor/question-types";
import { useClients, ClientRecord, ReportRecord } from "../../services/client-records/clients-provider";
import { useParameters } from "../../services/parameters/parameters-provider";
import { LightColor, PercentageCutoffListType } from "../../services/parameters/parameters-schema";
import { Account, ExtractedMorningstarData } from "./analysis";

// Decided against making heavy use of the following types (was originally thinking of trying out a monad-type pattern with checkable fields.)

// interface FailureCauses {
//   [key:string]: string,
// };

// type ExecutionOutcome<T> = {
//   success: false,
//   reasons: FailureCauses,
// } | {
//   success: true,
//   result: T,
// }

// const check = <T>(value: T) => {
//   return value === null || value === undefined;
// }

// const withChecks = <ReturnType>(callable: ()=>ReturnType, )=>{
//   // TODO
// }

export const useInferences = (report: ReportRecord) => {
  const {parameters} = useParameters();
  const {myClients} = useClients();
  const matchingClient = myClients.find(client=>client.id === report.clientId) as undefined | (ClientRecord & Partial<SurveyAnswers>);

  const accountsData = report.accountsData as undefined | Map<string, Account[]>;
  const morningstarData = report.morningstarData as undefined | ExtractedMorningstarData;

  // Risk Target
  // TODO: Incorporate more than one of the KYC questions
  const riskTolerance = matchingClient?.riskTolerance;
  const riskToleranceLevel = riskTolerance? {
    'Conservative': 1, 
    'Moderately Conservative': 2, 
    'Moderate': 3, 
    'Moderately Aggressive': 4, 
    'Aggressive': 5,
  }[riskTolerance] : undefined;

  // Current Equity Profile
  const totalEquityPercentage = morningstarData? morningstarData.assetAllocation.usStocks + morningstarData.assetAllocation.nonUSStocks: undefined;
  // TODO: Change to admin params
  let riskLevel:{score: number, descriptor: string}|undefined = undefined;
  if (totalEquityPercentage && totalEquityPercentage > 90) {
    riskLevel = {score: 5, descriptor: "Maximum Growth"};
  } else if (totalEquityPercentage && totalEquityPercentage > 70) {
    riskLevel = {score: 4, descriptor: "Capital Growth"};
  } else if (totalEquityPercentage && totalEquityPercentage > 50) {
    riskLevel = {score: 3, descriptor: "Balanced"};
  } else if (totalEquityPercentage && totalEquityPercentage > 30) {
    riskLevel = {score: 2, descriptor: "Current Income"};
  } else {
    riskLevel = {score: 1, descriptor: "Capital Preservation"};
  }

  const riskDifference = riskLevel && riskToleranceLevel? Math.abs(riskLevel.score - riskToleranceLevel): undefined;
  const riskLightColor = riskDifference && riskDifference === 0? "green": (riskDifference && riskDifference > 1)? "red" : "yellow";
  
  const riskRecommendation = riskLevel? {
    title: "Portfolio Risk/Growth Tradeoff",
    description: `The client has ${totalEquityPercentage}% of their portfolio invested in stock-based equity. This makes their current portfolio strategy ${riskLevel.descriptor}.`,
    tags: [] as string[],
    keys: {} as Record<string, string>,
    lightColor: riskLightColor as LightColor, // TODO: Resolve this hard-coding.
  } : undefined;

  // Bond Sector Analysis


  // Credit Quality Breakdown
  const highQualityBondPercentage = morningstarData?.creditQualityBreakdown.filter(breakdown=>["AAA", "AA", "A", "BBB"].includes(breakdown.creditRating.toUpperCase())).reduce((prev, cur)=>{return prev + cur.portfolioPercentage;}, 0);
  let lightDescriptor = {
    lightColor: "yellow",
    description: "The bond quality distribution was not measured."
  }
  if (highQualityBondPercentage && highQualityBondPercentage > 70) {
    lightDescriptor = {
      lightColor: "green",
      description: "The client has invested in high-quality bonds."
    }
  } else if (highQualityBondPercentage && highQualityBondPercentage > 50) {
    lightDescriptor = {
      lightColor: "yellow",
      description: "The client may be overexposed to risky bonds."
    }
  } else if (highQualityBondPercentage) {
    lightDescriptor = {
      lightColor: "red",
      description: "The client's bond portfolio is high-risk and increasing the volatility of their total holdings."
    }
  }
  const bondCreditRecommendation = lightDescriptor? {
    title: "Average Bond Credit Rating",
    description: lightDescriptor.description,
    tags: [] as string[],
    keys: {} as Record<string, string>,
    lightColor: lightDescriptor.lightColor as LightColor, // TODO: Resolve this hard-coding.
  } : undefined;

  // Stock Sectors

  // International Exposure
  const northAmericanEquityPercentage = morningstarData?.northAmericanEquityPercentage;
  const internationalPercentage = northAmericanEquityPercentage? 100-northAmericanEquityPercentage : undefined;
  const internationalPercentageParameters = parameters?.find(param => param.id === 'internationalEquityPercentage') as PercentageCutoffListType;
  // Find the right cutoff.
  const internationalPercentageLabel = internationalPercentage !== undefined? internationalPercentageParameters.data.filter(datum=>datum.cutoff >= internationalPercentage)[0].label: undefined; // Assumes data are sorted in ascending order.
  const internationalRecommendation = internationalPercentage? {
    title: "International Equity Exposure",
    description: `The client has ${internationalPercentage}% of their equity invested outside of North America. This is ${internationalPercentageLabel}.`,
    tags: [] as string[],
    keys: {} as Record<string, string>,
    lightColor: internationalPercentageLabel==="good"? "green":"yellow" as LightColor, // TODO: Resolve this hard-coding.
  } : undefined;

  // Effective Duration
  const effectiveBondDuration = morningstarData?.effectiveBondDuration;
  const bondDurationRecommendation = effectiveBondDuration? {
    title: "Effective Bond Duration",
    description: `The client's bond investments have an effective duration of ${effectiveBondDuration} years. The duration acts as a multiplier on the sensitivity of their bond portfolio to changes in federal interest rates.`,
    tags: [] as string[],
    keys: {} as Record<string, string>,
    lightColor: "yellow" as LightColor, // TODO: Resolve this hard-coding.
  } : undefined;

  // Equity Style



  // Recent Meeting Talking Points - TODO; currently just showing meeting listings.

  // All "flags"

  return {
    internationalRecommendation,
    riskRecommendation,
    bondDurationRecommendation,
    bondCreditRecommendation,
  };
}