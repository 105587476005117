import { Link, useLocation } from "react-router-dom"
import { useAuth } from "../services/AuthProvider"

export const Breadcrumbs: React.FC = () => {
  const {currentUser} = useAuth();
  const location = useLocation();

  const breadCrumb = (subpath:string, name:string, overrideLink?: string) => {
    return <>{location.pathname.includes(subpath) && location.pathname!==`/${subpath}`? <>&nbsp;|&nbsp;<Link to={`/${overrideLink || subpath}`}>{name}</Link></> : <></>}</>
  }

  return <>
    {currentUser? 
    <>
      {location.pathname !== "/home"? <><Link to="/home">Home</Link> 
      {/* {location.pathname.includes("smart-notes") && location.pathname!=="/smart-notes"? <>&nbsp;|&nbsp;<Link to="/smart-notes">Smart Notes</Link></> : <></>} */}
      {breadCrumb("smart-notes", "Smart Notes")}
      {breadCrumb("clients", "Clients")}
      {/* {breadCrumb("reports", "Clients", "clients")}  */}
      &nbsp;&gt; </> : <></>}
      <Link to="/my-info">My&nbsp;Info</Link>
    </>
    :<>
    <Link to="/splash">Back</Link>
    </>
    }
    &nbsp;|&nbsp;<Link to="/help">Help</Link>
  </>
}