import React, { useState } from 'react';
import {
  IonButton,
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonItem,
  IonLabel,
  IonInput,
  IonSelect,
  IonSelectOption,
  IonText,
  IonButtons,
  IonIcon,
  IonSpinner
} from '@ionic/react';
import { useClients } from './clients-provider';
import { closeOutline } from 'ionicons/icons';
import { getAuth } from 'firebase/auth';

const ClientCreator: React.FC = () => {
  const { mySeniorAdvisors, addClient } = useClients();
  const [fullName, setFullName] = useState<string>('');
  const [selectedSeniorAdvisor, setSelectedSeniorAdvisor] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const currentUser = getAuth().currentUser;

  const handleAddClient = async () => {
    if (!fullName.trim()) {
      setError('Full name is required.');
      return;
    }

    setError(null);
    setIsSubmitting(true);

    try {
      let seniorAdvisorUid = currentUser?.uid;
      if (mySeniorAdvisors.length === 1) {
        seniorAdvisorUid = mySeniorAdvisors[0].seniorAdvisorUid;
      } else if (mySeniorAdvisors.length > 1) {
        seniorAdvisorUid = selectedSeniorAdvisor;
      }

      if (seniorAdvisorUid) {
        await addClient(fullName, seniorAdvisorUid);
        setIsModalOpen(false);
      } else {
        setError("A senior advisor must be selected to assign the client to.");
        return;
      }
    } catch (err) {
      setError('Failed to add client.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && mySeniorAdvisors.length < 2) {
      handleAddClient();
    }
  };

  return (
    <>
      <IonButton onClick={() => setIsModalOpen(true)}>+ New Client</IonButton>
      <IonModal isOpen={isModalOpen} onDidDismiss={() => setIsModalOpen(false)}>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Create Client</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => setIsModalOpen(false)}>
                <IonIcon icon={closeOutline} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonItem>
            <IonLabel position="stacked">Full Name</IonLabel>
            <IonInput
              value={fullName}
              onIonChange={(e) => setFullName(e.detail.value!)}
              onKeyPress={handleKeyPress}
              disabled={isSubmitting}
              required
            />
          </IonItem>
          {mySeniorAdvisors.length > 1 && (
            <IonItem>
              <IonLabel position="stacked">Senior Advisor</IonLabel>
              <IonSelect
                value={selectedSeniorAdvisor}
                placeholder="Select One"
                onIonChange={(e) => setSelectedSeniorAdvisor(e.detail.value!)}
                disabled={isSubmitting}
              >
                {mySeniorAdvisors.map((advisor) => (
                  <IonSelectOption key={advisor.seniorAdvisorUid} value={advisor.seniorAdvisorUid}>
                    {advisor.seniorAdvisorEmail}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonItem>
          )}
          {mySeniorAdvisors.length === 1 && (
            <IonItem>
              <IonLabel>
                Client will be assigned to: {mySeniorAdvisors[0].seniorAdvisorEmail}
              </IonLabel>
            </IonItem>
          )}
          {error && (
            <IonText color="danger">
              <p>{error}</p>
            </IonText>
          )}
          <IonButton expand="block" onClick={handleAddClient} disabled={isSubmitting}>
            {isSubmitting ? <IonSpinner /> : 'Add Client'}
          </IonButton>
        </IonContent>
      </IonModal>
    </>
  );
};

export default ClientCreator;
