import React, { useEffect, useState, useRef } from "react";
import "firebase/storage";
import { getDownloadURL, getMetadata, ref, StorageReference } from "firebase/storage";
import { PDFDocument, StandardFonts, rgb, degrees, PDFPage, PDFFont, RGB, PDFPageDrawTextOptions, PDFImage, clip, popGraphicsState, pushGraphicsState, drawRectangle, endPath, drawSvgPath, drawImage, clipEvenOdd } from 'pdf-lib';
import { Document, Page, pdfjs } from "react-pdf";
import { IonButton } from "@ionic/react";

// https://stackoverflow.com/questions/75104923/react-pdf-displaying-text-found-inside-the-pdf-instead-of-the-pdf-itself
import "react-pdf/dist/esm/Page/TextLayer.css";
import { useData } from "../services/DataProvider";
import { useAuth } from "../services/AuthProvider";
import { storage } from "../App";
import { createPath } from "history";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// TODO: Shrink text on overflow?
async function addTextWithWrap(
  text: string,
  font: PDFFont,
  fontSize: number,
  maxWidth: number,
  maxHeight: number,
  page: PDFPage,
  startX: number,
  startY: number,
  center: boolean,
  otherProperties?: PDFPageDrawTextOptions,
) {
  const words = text.split(' ');
  let cursorX = 0;
  let cursorY = startY; // page.getHeight() - fontSize;
  let line = '';
  for (let i = 0; i < words.length; i++) {
    const word = words[i];
    const wordWidth = font.widthOfTextAtSize(word, fontSize);
    if (cursorX + wordWidth > maxWidth) {
      // Add the current line to the page and start a new line
      const leftoverWidth = maxWidth - cursorX;
      await page.drawText(line, {
        ...otherProperties,
        x: startX + (center? leftoverWidth/2 : 0),
        y: cursorY,
        size: fontSize,
        font,
      });
      line = word;
      cursorX = wordWidth;
      cursorY -= fontSize;
    } else {
      // Add the current word to the current line
      line += (line.length === 0 ? '' : ' ') + word;
      cursorX += wordWidth + (line.length === 1 ? 0 : font.widthOfTextAtSize(' ', fontSize));
    }
  }
  // Add the last line to the page
  const leftoverWidth = maxWidth - cursorX;
  await page.drawText(line, {
    ...otherProperties,
    x: startX + (center? leftoverWidth/2 : 0),
    y: cursorY,
    size: fontSize,
    font,
  });
}

// Commenting this function and using a workaround resizing with the canvas API.
// function placeImage(
//   page: PDFPage,
//   image: PDFImage,
//   frameX: number,
//   frameY: number,
//   frameWidth: number,
//   frameHeight: number,
//   cover?: boolean,
// ) {
//   // Clipping / Masking Images: https://github.com/Hopding/pdf-lib/issues/160#issuecomment-523205152
//   //    Answer redirected from: https://github.com/Hopding/pdf-lib/issues/322


//   // Define a clipping path using an SVG path string.
//   const svgPath = `M0,0 L${frameWidth},0 L${frameWidth},${-frameHeight} L0,${-frameHeight} Z`;

//   page.pushOperators(
//     // Inside pushOperators, list returned operators.
//     pushGraphicsState(), // Enter a drawing/masking state

//     ...drawSvgPath(svgPath, {
//       x: frameX,
//       y: frameY,
//       scale: undefined,
//       color: undefined,
//       borderColor: undefined,
//       borderWidth: 0,
//     }),


//     clip(),

//     // Clipping on the rectangle does work, but not on the image....

//     // ...drawRectangle({
//     //   x: frameX,
//     //   y: frameY,
//     //   width: 200, //frameWidth,
//     //   height: 200, //frameHeight,
//     //   borderWidth: 0,
//     //   borderColor: rgb(0, 0, 0),
//     //   color: rgb(1, 0, 0),
//     //   rotate: degrees(0),
//     //   xSkew: degrees(0),
//     //   ySkew: degrees(0)
//     // }),

//     endPath(),
//   );
//   page.drawImage(image, {
//     x: frameX,
//     y: frameY,
//     width: image.width, //frameWidth,
//     height: image.height, //frameHeight,
//   });
//   page.pushOperators(popGraphicsState()); // Exit the drawing/masking context.
// }

// async function convertImageToPNG(imageDataURL: string): Promise<string> {
//   return new Promise((resolve, reject) => {
//     const img = new Image();
//     img.src = imageDataURL;

//     img.onload = () => {
//       const canvas = document.createElement('canvas');
//       canvas.width = img.width;
//       canvas.height = img.height;

//       const ctx = canvas.getContext('2d');
//       if (!ctx) {
//         reject('Failed to get canvas context');
//         return;
//       }

//       ctx.drawImage(img, 0, 0);
//       resolve(canvas.toDataURL('image/png'));
//     };

//     img.onerror = (error) => {
//       reject(error);
//     };
//   });
// }

async function cropImageToAspectRatio( // Also converts to PNG
  imageDataURL: string,
  frameWidth: number,
  frameHeight: number,
  cover?: boolean
): Promise<string> {
  const targetAspectRatio = frameWidth/frameHeight;
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'anonymous';  // Trying to resolve "Tainted Canvas" error.
    img.src = imageDataURL;

    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      if (!ctx) {
        reject('Failed to get canvas context');
        return;
      }

      const imgAspectRatio = img.width / img.height;

      let targetWidth: number;
      let targetHeight: number;

      if ((!cover && imgAspectRatio < targetAspectRatio) || (cover && imgAspectRatio > targetAspectRatio)) {
        targetWidth = img.width * (targetAspectRatio / imgAspectRatio);
        targetHeight = img.height;
      } else {
        targetWidth = img.width;
        targetHeight = img.height / (targetAspectRatio / imgAspectRatio);
      }

      canvas.width = targetWidth;
      canvas.height = targetHeight;

      // Clear the canvas with a transparent color.
      ctx.fillStyle = 'rgba(0, 0, 0, 0)';
      ctx.fillRect(0, 0, targetWidth, targetHeight);

      // Draw the image onto the canvas, with cropping or transparent borders.
      if (cover) {
        ctx.drawImage(
          img,
          (targetWidth - img.width) / 2,
          (targetHeight - img.height) / 2
        );
      } else {
        ctx.drawImage(
          img,
          0,
          (targetHeight - img.height) / 2,
          img.width,
          img.height
        );
      }

      resolve(canvas.toDataURL('image/png'));
    };

    img.onerror = (error) => {
      reject(error);
    };
  });
}

// { r: number, g: number, b: number }
function hexToRGB(hex: string): RGB {
  const bigint = parseInt(hex.substring(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  //return { r: r / 255, g: g / 255, b: b / 255 };
  return rgb(r/255, g/255, b/255);
}

export interface PDFViewerProps {
  storageRef: StorageReference;
  toRecolor?: boolean; // TODO: Use
  unaffiliated?: boolean; // TODO: Use
}

const PDFCopyViewer: React.FC<PDFViewerProps> = ({ storageRef, toRecolor, unaffiliated }) => {
  const { currentUser } = useAuth();
  const uid = currentUser?.uid || "NO_USER_IDENTIFIED";
  const {marketingProfile, permissions} = useData();
  const disclosures = (marketingProfile.disclosures? marketingProfile.disclosures + " " : "") + "MJA Content Creator is separately owned and other entities and/or marketing names, products or services referenced here are independent of Royal Alliance Associates, Inc, Independence Financial Partners , OSJ 935 Jefferson Blvd Suite 2000, Warwick, RI 02886 (401)-732-4800, and any and all other entities. Past performance is no guarantee of future results. Please note that individual situations can vary.";

  const [pdfBytes, setPdfBytes] = useState<Uint8Array | null>(null);
  const [modifiedPdfBytes, setModifiedPdfBytes] = useState<Uint8Array | null>(null);
  const newBytes = modifiedPdfBytes || pdfBytes;
  const [logoURL, setLogoURL] = useState("");
  const [logoType, setLogoType] = useState(""); // File extension
  const [portraitURL, setPortraitURL] = useState("");
  const [portraitType, setPortraitType] = useState("");

  const [numPages, setNumPages] = useState<number>(1);
  const [pageNumber, setPageNumber] = useState<number>(1);

  useEffect(() => {
    const fetchPdfBytes = async () => {
      const pdfUrl = await getDownloadURL(storageRef);
      const existingPdfBytes = await fetch(pdfUrl).then(res => res.arrayBuffer());
      setPdfBytes(new Uint8Array(existingPdfBytes));
    };
    fetchPdfBytes();
  }, [storageRef]);

  const modifyPdfDocument = async () => {
    if (!pdfBytes) return;

    const pdfDoc = await PDFDocument.load(pdfBytes);
    const pages = pdfDoc.getPages();
    const firstPage = pages[0];

    const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
    // const font = await pdfDoc.embedFont(StandardFonts.TimesRoman);

    const portraitImageBytes = await fetch(await cropImageToAspectRatio(
      portraitURL,
      8.5,
      11,
      true,
    )).then(res => res.arrayBuffer());
    //const portraitImage = portraitType==="png"? (await pdfDoc.embedPng(portraitImageBytes)) : (await pdfDoc.embedJpg(portraitImageBytes));
    const portraitImage = await pdfDoc.embedPng(portraitImageBytes);

    const logoImageBytes = await fetch(logoURL).then(res => res.arrayBuffer());
    const logoImage = logoType==="png"? (await pdfDoc.embedPng(logoImageBytes)) : (await pdfDoc.embedJpg(logoImageBytes));

    const nameAndCredentials = marketingProfile.name + (marketingProfile.credentials? (", " + marketingProfile.credentials) : "");

    if (toRecolor) {
      const color1 = marketingProfile.color1 || "#aaaaaa";
      const color2 = marketingProfile.color2 || "#cccccc";
      const trianglePoints1 = [
        [firstPage.getWidth() * .49, -firstPage.getHeight() * 1],
        [firstPage.getWidth() * 1, -firstPage.getHeight() * 1],
        [firstPage.getWidth() * .745, -firstPage.getHeight() * .8],
      ];
      const svgPath1 = "M " + trianglePoints1.map((point)=>{return point.join(",")}).join(" L ") + " Z"; 
      const trianglePoints2 = [
        [firstPage.getWidth() * 1, -firstPage.getHeight() * .8],
        [firstPage.getWidth() * 1, -firstPage.getHeight() * 1],
        [firstPage.getWidth() * .745, -firstPage.getHeight() * .8],
      ];
      const svgPath2 = "M " + trianglePoints2.map((point)=>{return point.join(",")}).join(" L ") + " Z"; 
      // Set fill color and opacity
      firstPage.drawSvgPath(svgPath1, {
        x: 0,
        y: 0,
        color: hexToRGB(color1),
        opacity: 1, //0.75,
      });
      firstPage.drawSvgPath(svgPath2, {
        x: 0,
        y: 0,
        color: hexToRGB(color2),
        opacity: 1, //0.75,
      });

      // Cover bar at bottom
      const rectanglePoints = [
        [firstPage.getWidth() * 0, -firstPage.getHeight() * 0],
        [firstPage.getWidth() * 0, -firstPage.getHeight() * .095],
        [firstPage.getWidth() * 1, -firstPage.getHeight() * .095],
        [firstPage.getWidth() * 1, -firstPage.getHeight() * 0],
      ];
      const rectSVG = "M " + rectanglePoints.map((point)=>{return point.join(",")}).join(" L ") + " Z"; 
      firstPage.drawSvgPath(rectSVG, {
        x: 0,
        y: 0,
        color: hexToRGB(color2),
        opacity: 1, //0.75,
      });

      if (unaffiliated) {
        // If the versions had to be differentiated, other text would go here.
      } else {
        // If the versions had to be differentiated, other text would go here.
      }

      const portraitX = firstPage.getWidth() * .795; // .775;
      const portraitY = firstPage.getHeight() * .42; // .41
      const portraitWidth = firstPage.getWidth() * .085;
      firstPage.drawImage(portraitImage, {
        x: portraitX,
        y: portraitY,
        width:  portraitWidth,
        height:  firstPage.getHeight() * .085,
      });

      const fontSize = 8;

      addTextWithWrap(nameAndCredentials, font, fontSize, 3*portraitWidth, fontSize * 2, firstPage, portraitX - portraitWidth, portraitY - fontSize * 1.5, true);

      const roleAndFirm = marketingProfile.role + (marketingProfile.role && marketingProfile.organization? ", " : "") + marketingProfile.organization;

      addTextWithWrap(roleAndFirm, font, fontSize, 3*portraitWidth, fontSize * 2, firstPage, portraitX - portraitWidth, portraitY - fontSize * 3, true);

      // Add logo:

      const logoWidth = firstPage.getWidth() * .15;
      const logoHeight = logoImage.height / logoImage.width * logoWidth;
      firstPage.drawImage(logoImage, {
        x: firstPage.getWidth() * .05,
        y: firstPage.getHeight() * .89, // TODO: Vertically centered with "AMP Insights"
        width:  logoWidth,
        height:  logoHeight,
      });
      firstPage.drawText(marketingProfile.name, {
        x: firstPage.getWidth() * .25,
        y: firstPage.getHeight() * .15,
        size: 20,
        font: font,
      });
    } else { // Not to recolor
      const portraitX = firstPage.getWidth() * .07;
      const portraitY = firstPage.getHeight() * .55;
      const portraitWidth = firstPage.getWidth() * .1;
      const portraitHeight = firstPage.getHeight() * .1
      firstPage.drawImage(portraitImage, {
        x: portraitX,
        y: portraitY,
        width:  portraitWidth,
        height:  portraitHeight,
      });
      // placeImage(
      //   firstPage, 
      //   portraitImage, 
      //   portraitX,
      //   portraitY,
      //   portraitWidth,
      //   portraitHeight,
      // );
      const fontSize = 9;
      addTextWithWrap(nameAndCredentials, font, fontSize, 3*portraitWidth, fontSize * 2, firstPage, portraitX - portraitWidth, portraitY - fontSize * 1.5, true);
      addTextWithWrap(marketingProfile.role, font, fontSize, 3*portraitWidth, fontSize * 2, firstPage, portraitX - portraitWidth, portraitY - fontSize * 3, true);
  
      const logoWidth = firstPage.getWidth() * .25;
      const logoHeight = logoImage.height / logoImage.width * logoWidth;
      firstPage.drawImage(logoImage, {
        x: (firstPage.getWidth() - logoWidth)/2,
        y: firstPage.getHeight() * .65,
        width:  logoWidth,
        height:  logoHeight,
      });
    }

    // Place Disclosures last (over bottom bar if applicable)
    if (toRecolor) {
      const color2 = marketingProfile.color2 || "#cccccc"; // TODO: remove repetition
      const color2RGB = hexToRGB(color2)
      const color2Value = color2RGB.red + color2RGB.blue + color2RGB.green;
      const contrastColor = color2Value > 1.5 ? rgb(0, 0, 0) : rgb(1, 1, 1);
      addTextWithWrap( disclosures, font, 6, firstPage.getWidth() * 4/5, firstPage.getHeight() * 1, firstPage, firstPage.getWidth()/10, firstPage.getHeight() * .07, false, {color: contrastColor});
    } else {
      addTextWithWrap( disclosures, font, 6, firstPage.getWidth() * 4/5, firstPage.getHeight() * 1, firstPage, firstPage.getWidth()/10, firstPage.getHeight() * .04, false);
    }

    const newPdfBytes = await pdfDoc.save();
    setModifiedPdfBytes(newPdfBytes);
  };

  useEffect(() => {
    if (logoURL && portraitURL) {
      modifyPdfDocument();
    }
  }, [pdfBytes, logoURL, portraitURL]);

  useEffect(() => {
    // Load logoURL and portraitURL
    const logoPath = "user/" + uid + "/" + "logo";
    const logoFallback = "/assets/user_fallbacks/logo.jpg";

    const portraitPath = "user/" + uid + "/" + "portrait";
    const portraitFallback = "/assets/user_fallbacks/portrait.jpg";

    const logoRef = ref(storage, logoPath);
    // https://firebase.google.com/docs/storage/web/download-files
    getDownloadURL(logoRef).then(url => {
      getMetadata(logoRef).then((metadata)=>{
        setLogoURL(url);
        setLogoType(metadata.contentType?.split("/").at(-1) || "");
      });
    }).catch(error => {
      setLogoURL(logoFallback);
      setLogoType("jpg")
    });

    const portraitRef = ref(storage, portraitPath);
    // https://firebase.google.com/docs/storage/web/download-files
    getDownloadURL(portraitRef).then(url => {
      getMetadata(portraitRef).then((metadata)=>{
        setPortraitURL(url);
        setPortraitType(metadata.contentType?.split("/").at(-1) || "");
      });
    }).catch(error => {
      setPortraitURL(portraitFallback);
    });
  }, []);

  // useEffect(() => {
  //   async function renderPdfPage() {
  //     const newBytes = modifiedPdfBytes || pdfBytes;
  //     if (! newBytes) {
  //       return;
  //     }

  //     try {

  //     } catch (error) {
  //       console.error(error);
  //     }
  //   }

  //   renderPdfPage();
  // }, [pdfBytes, modifiedPdfBytes]);

  const downloadAsFile = (data: Uint8Array, filename: string) => {
    const url = URL.createObjectURL(new Blob([data], { type: 'application/pdf' }));
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    link.click();
  };

  const previewPdf = () => {
    if (!modifiedPdfBytes) return;

    const url = URL.createObjectURL(new Blob([modifiedPdfBytes], { type: 'application/pdf' }));
    const win = window.open();
    if (win !== null) {
      win.document.write(`<iframe src="${url}" width="100%" height="100%"></iframe>`);
    }
  };

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  if (!pdfBytes) return <div>Loading...</div>;

  return (
    <div style={{margin:"auto"}}>
      {/* <img src={page} alt="pdf preview" />; */}
      {/* <IonButton onClick={() => modifyPdfDocument()}>Modify PDF</IonButton>
      <IonButton onClick={() => previewPdf()}>Preview PDF</IonButton> */}
      {modifiedPdfBytes? //&&
      // ( permissions.hasSubscription || permissions.hasTestSubscription)?
        <IonButton disabled={!modifiedPdfBytes} onClick={() => downloadAsFile(modifiedPdfBytes, 'modified-document.pdf')}>Download</IonButton>
        :
        <IonButton routerLink="/billing">Set Up Billing to Download</IonButton>
      }

      {newBytes && 
        <Document file={{ data: newBytes }} onLoadSuccess={onDocumentLoadSuccess}>
          <Page pageNumber={1} renderTextLayer={false} renderAnnotationLayer={false}/>
        </Document>
      }
    </div>
  );
};

export default PDFCopyViewer;