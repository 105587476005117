import React, { createContext, useState, useEffect, useContext, ReactNode } from 'react';
import { collection, onSnapshot, query, where, addDoc, deleteDoc, doc, updateDoc, setDoc, serverTimestamp } from 'firebase/firestore';
import { firestore as db } from "../../App"; // Your firebase configuration
import { getAuth } from 'firebase/auth';

interface PermissionsRecord {
  id: string;
  seniorAdvisorUid: string;
  seniorAdvisorEmail: string;
  juniorAdvisorEmail: string;
}

export interface ClientRecord {
  id: string;
  fullName: string;
  seniorAdvisorUid: string;
  createdByEmail: string;
}

export interface Report {
  clientId: string;
  // fileMetadataIds: string[]; // Don't need this; the metadata references the report by id; we can just lookup that way.
  createdAt?: Date;
  [key: string]: any;
}
export type ReportRecord = Report & {id: string};

interface ClientsContextType {
  myJuniorAdvisors: PermissionsRecord[];
  mySeniorAdvisors: PermissionsRecord[];
  myClients: ClientRecord[];
  myReports: Map<string, ReportRecord[]>;
  addJuniorAdvisor: (email: string) => Promise<void>;
  removeJuniorAdvisor: (id: string) => Promise<void>;
  addClient: (fullName: string, seniorAdvisorUid: string) => Promise<void>;
  updateClient: (id: string, updatedData: Partial<ClientRecord>) => Promise<void>;
  removeClient: (id: string) => Promise<void>;
  addReport: (reportData: Omit<Report, 'createdAt'>) => Promise<void>;
  updateReport: (id: string, updatedData: Partial<Report>) => Promise<void>;
  removeReport: (id: string) => Promise<void>;
}

const ClientsContext = createContext<ClientsContextType | null>(null);

interface ClientsProviderProps {
  children: ReactNode;
}

export const ClientsProvider: React.FC<ClientsProviderProps> = ({ children }) => {
  const [myJuniorAdvisors, setMyJuniorAdvisors] = useState<PermissionsRecord[]>([]);
  const [mySeniorAdvisors, setMySeniorAdvisors] = useState<PermissionsRecord[]>([]);
  const [myClientsMap, setMyClientsMap] = useState<Map<string, ClientRecord>>(new Map());
  const [myReportsMap, setMyReportsMap] = useState<Map<string, ReportRecord[]>>(new Map());

  const auth = getAuth();
  const currentUser = auth.currentUser;

  // Subscribe to junior advisors
  useEffect(() => {
    if (currentUser) {
      const juniorAdvisorsQuery = query(
        collection(db, 'permissions'),
        where('seniorAdvisorUid', '==', currentUser.uid)
      );

      const unsubscribeJuniorAdvisors = onSnapshot(juniorAdvisorsQuery, (snapshot) => {
        const juniorAdvisors = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        } as PermissionsRecord));
        setMyJuniorAdvisors(juniorAdvisors);
      });

      return () => unsubscribeJuniorAdvisors();
    }
  }, [currentUser]);

  // Subscribe to senior advisors
  useEffect(() => {
    if (currentUser) {
      const seniorAdvisorsQuery = query(
        collection(db, 'permissions'),
        where('juniorAdvisorEmail', '==', currentUser.email)
      );

      const unsubscribeSeniorAdvisors = onSnapshot(seniorAdvisorsQuery, (snapshot) => {
        const seniorAdvisors = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        } as PermissionsRecord));
        setMySeniorAdvisors(seniorAdvisors);
      });

      return () => unsubscribeSeniorAdvisors();
    }
  }, [currentUser]);

  // Subscribe to clients
  useEffect(() => {
    if (currentUser) {
      const unsubscribeFunctions: (() => void)[] = [];

      const clientQueries = [
        query(collection(db, 'clients'), where('seniorAdvisorUid', '==', currentUser.uid))
      ];

      if (mySeniorAdvisors.length > 0) {
        const seniorUids = mySeniorAdvisors.map(sa => sa.seniorAdvisorUid);
        seniorUids.forEach(uid => {
          clientQueries.push(query(collection(db, 'clients'), where('seniorAdvisorUid', '==', uid)));
        });
      }

      clientQueries.forEach(clientQuery => {
        const unsubscribe = onSnapshot(clientQuery, (snapshot) => {
          setMyClientsMap(prevClientsMap => {
            const clientMap = new Map(prevClientsMap);

            // Track changes to individual records, since the map is an aggregate across all the affiliated advisors, and we don't want to repopulate the entire collection every time a single record is updated.
            snapshot.docChanges().forEach(change => {
              if (change.type === 'added' || change.type === 'modified') {
                const clientData = change.doc.data() as ClientRecord;
                clientMap.set(change.doc.id, {...clientData, id: change.doc.id});
              } else if (change.type === 'removed') {
                clientMap.delete(change.doc.id);
              }
            });

            return clientMap;
          });
        });
        unsubscribeFunctions.push(unsubscribe);
      });

      // Cleanup function to unsubscribe all listeners
      return () => {
        unsubscribeFunctions.forEach(unsubscribe => unsubscribe());
        // Reset the clients - if seniorAdvisors changes (or the currentUser), most of the data is going to change anyway, and we don't want to leak the data during an asynchronous update.
        setMyClientsMap(new Map());
      };
    }
  }, [currentUser, mySeniorAdvisors]);

  // Subscribe to reports
  useEffect(() => {
    if (currentUser) {
      // Remove clients that are no longer in myClientsMap
      setMyReportsMap(prevReportsMap => {
        const newReportsMap = new Map(prevReportsMap);
        prevReportsMap.forEach((_, clientId) => {
          if (!myClientsMap.has(clientId)) {
            newReportsMap.delete(clientId);
          }
        });
        return newReportsMap;
      });

      const unsubscribeFunctions: (() => void)[] = [];

      myClientsMap.forEach((_, clientId) => {
        const reportQuery = query(
          collection(db, 'reports'),
          where('clientId', '==', clientId)
        );

        const unsubscribe = onSnapshot(reportQuery, (snapshot) => {
          setMyReportsMap(prevReportsMap => {
            const reportMap = new Map(prevReportsMap);

            const clientReports = snapshot.docs.map(doc => {
              const reportData = doc.data();
              return { ...reportData, createdAt: reportData.createdAt.toDate(), id: doc.id } as ReportRecord;
            });

            reportMap.set(clientId, clientReports);

            return reportMap;
          });
        });
        unsubscribeFunctions.push(unsubscribe);
      });

      return () => {
        unsubscribeFunctions.forEach(unsubscribe => unsubscribe());
      };
    }
  }, [currentUser, myClientsMap]);

  // Add a junior advisor
  const addJuniorAdvisor = async (email: string): Promise<void> => {
    if (currentUser) {
      const docId = `${currentUser.uid}_${email}`;
      await setDoc(doc(db, 'permissions', docId), {
        seniorAdvisorUid: currentUser.uid,
        seniorAdvisorEmail: currentUser.email,
        juniorAdvisorEmail: email,
      });
    }
  };

  // Remove a junior advisor
  const removeJuniorAdvisor = async (id: string): Promise<void> => {
    await deleteDoc(doc(db, 'permissions', id));
  };

  // Add a client
  const addClient = async (fullName: string, seniorAdvisorUid: string): Promise<void> => {
    if (currentUser) {
      const seniorAdvisorRecord = mySeniorAdvisors.find(sa => sa.seniorAdvisorUid === seniorAdvisorUid);
      if (seniorAdvisorRecord || seniorAdvisorUid === currentUser.uid) {
        console.log(`${fullName} ${seniorAdvisorUid} ${currentUser.email}`);
        await addDoc(collection(db, 'clients'), {
          fullName,
          seniorAdvisorUid,
          createdByEmail: currentUser.email,
        });
      } else {
        throw new Error('You do not have permission to add a client for this senior advisor.');
      }
    }
  };

  // Update a client
  const updateClient = async (id: string, updatedData: Partial<ClientRecord>): Promise<void> => {
    const clientDocRef = doc(db, 'clients', id);
    // Testing permissions issues if I scrub id, seniorAdvisorUid, and creatorEmail from record.
    // const {id: clientId, seniorAdvisorUid, createdByEmail, ...filteredUpdateData} = updatedData;
    const filteredUpdateData = updatedData; // Doesn't seem that was the issue. Something to do with how I was providing the clientId for comparison in the firestore rule.
    // Note: New rule is mildly insecure - user could transfer ownership of record with a poorly written update object.
    await updateDoc(clientDocRef, filteredUpdateData);
  };

  // Remove a client
  const removeClient = async (id: string): Promise<void> => {
    await deleteDoc(doc(db, 'clients', id));
  };

  // Add a report
  const addReport = async (reportData: Omit<Report, 'createdAt'>): Promise<void> => {
    await addDoc(collection(db, 'reports'), {
      ...reportData,
      createdAt: serverTimestamp(),
    });
  };

  // Update a report
  const updateReport = async (id: string, updatedData: Partial<Report>): Promise<void> => {
    const reportDocRef = doc(db, 'reports', id);
    await updateDoc(reportDocRef, updatedData);
  };

  // Remove a report
  const removeReport = async (id: string): Promise<void> => {
    await deleteDoc(doc(db, 'reports', id));
  };

  // Convert the map to an array for the context consumer
  const myClientsArray = Array.from(myClientsMap.values());

  return (
    <ClientsContext.Provider value={{ 
      myJuniorAdvisors, 
      mySeniorAdvisors, 
      myClients: myClientsArray, 
      myReports: myReportsMap,
      addJuniorAdvisor, 
      removeJuniorAdvisor, 
      addClient, 
      updateClient, 
      removeClient,
      addReport,
      updateReport,
      removeReport,
    }}>
      {children}
    </ClientsContext.Provider>
  );
};

export const useClients = (): ClientsContextType => {
  const context = useContext(ClientsContext);
  if (!context) {
    throw new Error('useClients must be used within a ClientsProvider');
  }
  return context;
};
