import React, { useState } from 'react';
import {
  IonButton,
  IonAlert,
  IonIcon,
  IonText
} from '@ionic/react';
import { useClients } from './clients-provider';
import { trashOutline } from 'ionicons/icons';
import { getAuth } from 'firebase/auth';
import { ClientRecord } from "./clients-provider";

interface ClientDeleteButtonProps {
  client: ClientRecord;
  onDelete?: ()=>void;
}

const ClientDeleteButton: React.FC<ClientDeleteButtonProps> = ({ client, onDelete }) => {
  const { removeClient } = useClients();
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const currentUser = getAuth().currentUser;

  const hasPermissionToDelete = currentUser &&
    (currentUser.uid === client.seniorAdvisorUid || currentUser.email === client.createdByEmail);

  const handleDeleteClient = async () => {
    setError(null);
    try {
      await removeClient(client.id);
      setShowConfirm(false);
      if (onDelete) {
        onDelete();
      }
    } catch (err) {
      setError('Failed to delete client.');
    }
  };

  if (!hasPermissionToDelete) {
    return null;
  }

  return (
    <>
      <IonButton color="danger" onClick={() => setShowConfirm(true)}>
        <IonIcon slot="icon-only" icon={trashOutline} />
      </IonButton>
      <IonAlert
        isOpen={showConfirm}
        onDidDismiss={() => setShowConfirm(false)}
        header={'Confirm Delete'}
        message={'Are you sure you want to delete this client? This action cannot be undone.'}
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => {
              setShowConfirm(false);
            }
          },
          {
            text: 'Delete',
            handler: handleDeleteClient
          }
        ]}
      />
      {error && (
        <IonText color="danger">
          <p>{error}</p>
        </IonText>
      )}
    </>
  );
};

export default ClientDeleteButton;
