import { IonSpinner } from "@ionic/react";
import { getDownloadURL, ref, uploadBytes, UploadMetadata } from "firebase/storage";
import React, { useEffect, useState } from "react"
import { storage } from "../App";

export const UnauthedImageSelector: React.FC<{fileName:string, fallbackImageURL:string, style?:React.CSSProperties}> = ({fileName, fallbackImageURL, style, ...props})=>{

  const [imageURL, setImageURL] = useState<string>(""); // Empty source renders as blank; alternative: use a placeholder gray image.
  const [uploading, setUploading] = useState(false);

  const fileDescriptor = fileName.split("/").at(-1);

  useEffect(() => {
    const storageRef = ref(storage, fileName);
    // https://firebase.google.com/docs/storage/web/download-files
    getDownloadURL(storageRef).then(url => {
      setImageURL(url);
    }).catch(error => {
      setImageURL(fallbackImageURL);
    });
  }, [fileName, fallbackImageURL]);

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;
  
    const storageRef = ref(storage, fileName);
    setUploading(true);
    // https://firebase.google.com/docs/storage/web/file-metadata
    const metadata: UploadMetadata = {
      customMetadata: {
        // public: "true", // A possible way to manage file access permissions at upload time.
      }
    }
    // uploadBytes returns an object that an event listener can be attached to.
    // https://firebase.google.com/docs/storage/web/upload-files
    uploadBytes(storageRef, file, metadata).then((snapshot) => {
      setUploading(false);
      getDownloadURL(storageRef).then(url => {
        setImageURL(url);
      }).catch(error => {
        setImageURL(fallbackImageURL);
      });
    }).catch((reason)=>{
      console.log(reason);
    });
  };

  // onClick in AdminToggle prevents outer links/actions from activating on admin actions. Very slim possibilitiy of issues: if the admin toggle covers the entire object, it could prevent normal functioning of the object.
  return (
    <div>
      <img style={{...style, border:"1px solid #AAAAAA"}} src={imageURL} alt={fileDescriptor} />
      {/*style={{position: "absolute", top:"0", left:"0"}}*/}
      <div onClick={(event)=>{event?.stopPropagation();}}>
          Update: <input type="file" onChange={handleFileUpload}/>
          {uploading? <IonSpinner/>: <></>}
      </div>
    </div>
  );
}