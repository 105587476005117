import { IonCol, IonContent, IonGrid, IonPage, IonRouterLink, IonRow } from "@ionic/react";
import React from "react";
import { Breadcrumbs } from "../components/Breadcrumbs";
import { Logout } from "../components/Logout";
import { Link } from "react-router-dom";
import { AdminLinks } from "../components/admin-links";

const InfoStub: React.FC<{ title: string, backRouterLink?:string, children: React.ReactNode }> = ({ title, backRouterLink, children, ...props }) => {
  return (
    <IonPage>
      <IonContent>
        <IonGrid style={{ height: '100vh', display: "flex", flexDirection: "column" }}>
          <IonRow>
            <IonCol>
              <p style={{ textAlign: "left" }}><Breadcrumbs /></p>
              <br/>
              {backRouterLink && <Link to={backRouterLink} style={{fontSize:"1.25em"}}>← Back</Link>}
            </IonCol>
            <IonCol>
              <h1 style={{ textAlign: "center" }}>{title}</h1>
            </IonCol>
            <IonCol>
              <p style={{ textAlign: "right" }}><AdminLinks/><Logout /></p>
            </IonCol>
          </IonRow>
          <IonRow style={{ flex: 1, display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center" }}>
            <IonCol size="12" style={{ height: '100%' }}>
              {children}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default InfoStub;
